import React from 'react';
import styled from 'styled-components';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

const StyledSelect = styled(Select)`
  &,
  .MuiSelect-root {
    //max-height: 40px;
  }

  .MuiSelect-root {
    //padding-top: 10px;
    //padding-bottom: 10px;
  }
`;

const InputDropdown = (props) => {
  const {
    label,
    borderLabel,
    name,
    placeholder = '',
    formRef,
    fieldError,
    customErrorMessage,
    defaultValue,
    borderColor,
    color,
    caretColor,
    formType,
    register,
    choices = [],
    control,
    onChange = () => {},
  } = props;
  return (
    <FormControl variant="outlined" error={fieldError}>
      {/*<InputLabel htmlFor="outlined-dropdown">{borderLabel}</InputLabel>*/}
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            select
            label={borderLabel}
            fullWidth
            size={'small'}
            // SelectProps={{
            //   MenuProps: {
            //     anchorOrigin: {
            //       vertical: 'bottom',
            //       horizontal: 'left',
            //     },
            //     getContentAnchorEl: null,
            //   },
            // }}
          >
            {choices.map((option) => (
              <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                <div>{option.label}</div>
              </MenuItem>
            ))}
          </TextField>
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </FormControl>
  );
};

export default InputDropdown;
