import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { theme } from '../../theme';
import CloseIcon from '@mui/icons-material/Close';
import HamburgerIcon from '../../assets/svg/hamburger.svg';
import ChevronLeftIcon from '../../assets/svg/chevron-left.svg';
import ListLoadingIndicator from '../LoadingIndicators/ListLoadingIndicator';
import { ViewLimiter } from '../ViewParts';

export const useStyles = makeStyles((materialTheme) => ({
  root: {
    flexGrow: 1,
    maxWidth: '100vw',
    maxHeight: '64px',
  },
  rightActionButtonRoot: {
    paddingRight: 0,
  },
  menuButton: {
    // marginRight: materialTheme.spacing(2),
  },
}));

const AppBarContainer = styled.div`
  max-height: ${theme.header.heightPxVal}px;

  .MuiToolbar-root {
    padding-left: 0px;
    padding-right: 0px;
    //background-color: ${theme.colors.sth};

    .MuiTypography-h6,
    .MuiButton-label {
      //color: ${theme.colors.sth};
    }
  }
  .MuiAppBar-positionFixed {
    box-shadow: none !important;
    padding-left: ${theme.paddings.appPaddingPxVal}px;
    padding-right: ${theme.paddings.appPaddingPxVal}px;
    background-color: ${theme.colors.white};

    .MuiToolbar-root {
      width: 100%;
      max-width: ${theme.responsive.maxContentWidthPxVal}px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const DetailsTitle = styled.div`
  flex-grow: 1;
  text-align: left;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.17px;
  margin-left: 15px;
  color: ${theme.colors.black};
`;

const Title = styled.div`
  flex-grow: 1;
  text-align: left;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.17px;
  color: ${theme.colors.black};
`;

const StyledIconButton = styled(IconButton)`
  * {
    // color: ${theme.colors.darkBrown};
  }
`;

const ButtonContainer = styled.div`
  .MuiButton-label {
    color: ${theme.colors.blueTint2};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15em;
    text-align: right;
  }
`;

const LoadingIndicatorViewLimiter = styled(ViewLimiter)`
  margin-top: -5px;
`;

const navigationTypes = ['menu', 'back', null];

function CustomAppBar(props) {
  const {
    title = 'Recomendations',
    onAppBarButtonClick,
    actionButtonLabel = 'FILTER',
    onCloseClick = null,
    navigationType = 'menu',
    clickRoute,
    history,
    setIsMenuDrawerOpen,
    showGlobalLoadingIndicator,
  } = props;

  const classes = useStyles();

  const onMenuClick = () => {
    clickRoute ? history.push(clickRoute) : setIsMenuDrawerOpen(true);
  };

  const onBackClick = () => {
    history.goBack();
  };

  return (
    <AppBarContainer className={classes.root}>
      <AppBar position="fixed">
        <Toolbar className="toolbar-container">
          {navigationType === 'menu' ? (
            <StyledIconButton edge="start" color="inherit" aria-label="menu" onClick={onMenuClick}>
              <img src={HamburgerIcon} alt={'menu'} />
            </StyledIconButton>
          ) : navigationType === 'back' ? (
            <StyledIconButton edge="start" color="inherit" aria-label="back" onClick={onBackClick}>
              <img src={ChevronLeftIcon} alt={'back'} />
            </StyledIconButton>
          ) : (
            <StyledIconButton edge="start" color="inherit" />
          )}
          {onCloseClick && (
            <StyledIconButton
              edge="start"
              color="inherit"
              onClick={onCloseClick}
              aria-label="close"
            >
              <CloseIcon />
            </StyledIconButton>
          )}
          {navigationType === 'back' ? (
            <DetailsTitle>{title}</DetailsTitle>
          ) : (
            <Title>{navigationType !== 'menu' ? title : ''}</Title>
          )}
          {actionButtonLabel && onAppBarButtonClick && (
            <ButtonContainer>
              <Button
                // color="inherit"
                onClick={onAppBarButtonClick?.onClick}
                classes={{ root: classes.rightActionButtonRoot }}
              >
                {actionButtonLabel}
              </Button>
            </ButtonContainer>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
      {showGlobalLoadingIndicator && (
        <LoadingIndicatorViewLimiter>
          <ListLoadingIndicator type="linear" className={'ListLoadingIndicator'} />
        </LoadingIndicatorViewLimiter>
      )}
    </AppBarContainer>
  );
}

export default withRouter(CustomAppBar);
