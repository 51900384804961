import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const redirectWithKey = (pathname, key, search) => {
  history.push({
    pathname: pathname,
    search: search,
    state: { key },
  });
};
