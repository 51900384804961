import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;

export const getMenuBadges = createAsyncThunk('config/menuBadges', async (data, thunkAPI) => {
  const response = await axios.get(`/menu_badges/`);
  return response.data;
});

export const getDropdownsChoices = createAsyncThunk(
  'auth/getDropdownChoices',
  async (data, thunkAPI) => {
    const response = await axios.all([getConfigsPromise, getBuildingsPromise]);
    return response;
  }
);

export const postRegisterDevice = createAsyncThunk(
  'auth/registerDevice',
  async (data, thunkAPI) => {
    const { registration_id, name, device_id } = data;

    const response = await axios.post(`/devices/register/`, {
      registration_id,
      name,
      device_id,
      active: true,
      type: 'web',
    });
    return response.data;
  }
);

// duplicated here because of weird initialization error
export const signOut = createAsyncThunk('auth/signOut', async (data, thunkAPI) => {
  const response = await axios.delete('/auth/sign-out/');
  return response.data;
});

const getConfigsPromise = axios.get(`${baseUrl}/config/`).then((res) => res.data);
const getBuildingsPromise = axios.get(`${baseUrl}/buildings/short/`).then((res) => res.data);
