import React, { useEffect, useState } from 'react';
import NewServiceHeader from '../NewServiceHeader';
import NewServiceLayout from '../NewServiceLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAvailableServiceDetails } from '../../../reducers/residents.actions';
import { getSearchParam } from '../../../helpers/utilities';
import { RequestStatus } from '../../../helpers/RequestStatus';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import AliceHomeTitleLogo from '../../../assets/images/Rectangle@3x.png';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import { getNewPredefinedServicePreferencesPath } from '../../../routes';
import Button from '@mui/material/Button';
import MobileDrawer from '../../../components/MobileDrawer';

const PredefinedService = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const theme = useTheme();

  const {
    availableServices: {
      details: { item, fetchStatus },
    },
  } = useSelector((state) => state.residents);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const homeVisitId = getSearchParam('home_visit', history) ?? null;

  useEffect(() => {
    const residentId = +getSearchParam('resident', history);
    const id = +getSearchParam('service', history);
    residentId &&
      id &&
      dispatch(
        getAvailableServiceDetails({
          residentId,
          id,
          homeVisitId: homeVisitId ?? null,
        })
      );
  }, [history.location.search]);

  return RequestStatus.isFetching(fetchStatus) ? (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        my: 3,
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <NewServiceLayout
      onClick={() =>
        history.push(`${getNewPredefinedServicePreferencesPath()}${history.location.search}`)
      }
      // buttonAlignedToBottom
    >
      <NewServiceHeader title={item !== null ? item.name : ''} />
      <img
        src={item?.image ? item.image.original : AliceHomeTitleLogo}
        style={{
          width: '100%',
          maxHeight: '200px',
          borderRadius: '4px',
          ...(!item?.image
            ? {
                objectFit: 'contain',
                padding: '16px',
                backgroundColor: theme.palette.divider,
                width: 'calc(100% - 2 * 16px)',
              }
            : {
                objectFit: 'cover',
                marginBottom: '16px',
              }),
        }}
      />
      <Typography
        variant={'subtitle2'}
        dangerouslySetInnerHTML={{ __html: item?.description }}
        align={'left'}
        sx={{
          wordBreak: 'break-word',
        }}
      />
      <Typography variant={'h6'} align={'left'} sx={{ fontWeight: 'bold', my: 1 }}>
        {item?.price}
      </Typography>
      {item?.long_description_action && item?.long_description && (
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Button
            sx={{
              textAlign: 'left',
              p: 0,
              textTransform: 'none',
            }}
            onClick={() => setDrawerOpen(true)}
          >
            {item?.long_description_action}
          </Button>
          <MobileDrawer
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            height={null}
            hasHeader
            headerConfig={{
              title: item?.name,
              subTitle: null,
              showDivider: true,
            }}
          >
            <Typography
              variant={'subtitle1'}
              dangerouslySetInnerHTML={{ __html: item?.long_description }}
            />
          </MobileDrawer>
        </Box>
      )}
    </NewServiceLayout>
  );
};

export default PredefinedService;
