import axios from 'axios';
import { UNAUTH_USER } from '../reducers/app.actionTypes';
import { getSessionToken } from './utilities';
import { getLoginPath } from '../routes';
import { unauthUser } from '../reducers/auth.reducer';
import { format } from 'date-fns';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const isIgnore401 = () => {
  const parameter = process.env.REACT_APP_IGNORE_401;
  return parameter && parameter === 'true';
};

export default class NetworkService {
  static interceptor401;

  static setupDefaultHeaders(token) {
    if (token) axios.defaults.headers.common['Authorization'] = `Token ${token}`;
    axios.defaults.headers.common['X-TIMEZONE'] = format(new Date(), 'xx');
  }

  static resetDefaultHeaders(token) {
    axios.defaults.headers.common = {};
  }

  static syncHeaderTokenWithStorage() {
    const token = getSessionToken();
    if (token && token.length > 1) this.setupDefaultHeaders(token);
  }

  static setupInterceptors(store, history) {
    // Add a response interceptor
    this.interceptor401 = axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (+error.response.status === 401 && !isIgnore401()) {
          localStorage.clear();
          NetworkService.resetDefaultHeaders();
          store.dispatch(unauthUser());
          // move this below to store action
          history.push(getLoginPath());
          axios.interceptors.request.eject(this.interceptor401);
        }
        return Promise.reject(error);
      }
    );
  }
}
