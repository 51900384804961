import axios from 'axios';

export default async function uploadImage(image) {
  const formData = new FormData();
  formData.append('file', image);

  return axios
    .post("/attachments/upload/", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({data}) => data.data);
}
