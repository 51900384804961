import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { theme } from '../../theme';
import Avatar from '../../components/Avatar';
import { formatChatDate } from '../../helpers/dateFormats';
import { ElementContent1 } from '../../components/List/ListItem';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

const MessageImageContainer = styled.div`
  margin-bottom: 10px;
  min-height: 70px;
  display: block;
`;

export const MessageImage = styled.img`
  width: 70px;
  height: 70px;
  display: block;
  border-radius: 5px;
`;

export const GalleryWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
`;

export const FullImage = styled.img`
  width: 95vw;
  max-height: 95vh;
  object-fit: contain;
  //border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const SingleMessageWrapper = styled.div`
  margin-right: auto;
  text-align: left;
  width: 80%;
  min-height: 80px;
  height: auto;
  margin-top: 6px;

  & img,
  > div {
    margin-right: auto;
    text-align: left;
    margin-top: 8px;
  }

  &:last-child {
    margin-bottom: 6px;
  }

  ${({ isOwner }) =>
    isOwner &&
    css`
      margin-left: auto;
      margin-right: 0px;
      text-align: right;

      & img,
      & > div {
        margin-left: auto;
        margin-right: 0px;
        text-align: right;
      }
    `}
`;

const StyledSingleMessage = styled.div`
  padding: 15px 10px;
  border-radius: 8px;
  border-bottom-left-radius: 0px;
  text-align: left;
  width: 80%;
  background: ${theme.colors.lightGray};
  white-space: pre-wrap;

  ${({ isOwner }) =>
    isOwner &&
    css`
      text-align: right;
      background: ${theme.colors.blueTint5};
      border-radius: 8px;
      border-bottom-right-radius: 0px;
    `}
`;

const MessageUserInfoContainer = styled.div`
  display: flex;
`;

const DateContainer = styled(ElementContent1)`
  line-height: 40px;
  padding-top: 5px;
  margin-left: ${(props) => (props.isOwner ? 'auto' : '5px')};
  margin-right: ${(props) => (props.isOwner ? '5px' : 'auto')};
`;

const MessageAvatar = styled(Avatar)`
  ${(props) => (!props.isOwner ? 'margin-right: 0px !important' : 'margin-left: 0px !important')};
`;

const SingleMessage = (props) => {
  const { children, action = {}, images = [], isOwner, avatar, created, senderDetails } = props;

  const [isGalleryOpen, setIsGalleryOpen] = useState(false);

  const SenderDataContainer = () => (
    <Box
      sx={{
        pt: '4px',
        pb: '12px',
        ml: isOwner ? 'auto' : '5px',
        mr: isOwner ? '5px' : 'auto',
      }}
    >
      <Typography variant={'body2'} color={'textSecondary'}>
        {senderDetails?.full_name}
      </Typography>
      <Typography variant={'body2'} color={'textSecondary'}>
        {formatChatDate(created)}
      </Typography>
    </Box>
  );

  return (
    <SingleMessageWrapper isOwner={isOwner} className={'single-message-wrapper'}>
      <StyledSingleMessage isOwner={isOwner}>
        {images[0]?.original && isGalleryOpen && (
          <GalleryWrapper onClick={() => setIsGalleryOpen(false)}>
            <FullImage src={images[0]?.original} />
          </GalleryWrapper>
        )}

        {images[0]?.detail_thumbnail ? (
          <MessageImageContainer>
            <MessageImage
              src={images[0]?.detail_thumbnail}
              onClick={() => setIsGalleryOpen(true)}
            />
          </MessageImageContainer>
        ) : (
          children
        )}
      </StyledSingleMessage>
      <MessageUserInfoContainer>
        {isOwner && SenderDataContainer()}
        {/*{isOwner && <DateContainer isOwner={isOwner}>{formatChatDate(created)}</DateContainer>}*/}
        <MessageAvatar src={avatar} isOwner={isOwner} />
        {/*{!isOwner && <DateContainer isOwner={isOwner}>{formatChatDate(created)}</DateContainer>}*/}
        {!isOwner && SenderDataContainer()}
      </MessageUserInfoContainer>
    </SingleMessageWrapper>
  );
};

export default SingleMessage;
