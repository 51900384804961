import React from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';

export const ListRow = styled.div`
  margin-top: 10px;
  padding-bottom: ${(props) => (props.noBottomSeparator ? 0 : 18)}px;
  border-bottom: ${(props) =>
    props.noBottomSeparator ? 'none' : '1px solid ' + theme.colors.lightGray};
`;
const StyledListRow = styled(ListRow)`
  display: flex;
`;
export const TitleTop = styled.div`
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0.15em;
  text-align: left;
  color: ${theme.colors.medGray};
`;
export const ElementTitle = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: ${(props) => (props.isNew ? 700 : 400)};
  line-height: 27px;
  letter-spacing: 0.17px;
  text-align: left;
  ${(props) => (props.disabled ? 'color: ' + theme.colors.medGray + ';' : '')}
`;
export const ElementContent1 = styled.div`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.17px;
  text-align: left;
  color: ${(props) => (props.disabled ? theme.colors.medLightGray : theme.colors.medGray)};
`;
export const ElementContent2 = styled.div`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.17px;
  text-align: left;
`;
export const ElementNote = styled.div``;
export const TitleContainer = styled.div`
  display: flex;
  position: relative;
`;
export const BadgeContainer = styled.div`
  position: absolute;
  margin-left: auto;
  padding-left: 27px;
  padding-right: 27px;
  background-color: ${theme.colors.badgeGray};
  color: ${theme.colors.medLightGray};
  height: 28px;
  line-height: 30px;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.15em;
  text-align: left;
  right: -${theme.paddings.appPaddingPxVal}px;
  border-radius: 4px 0px 0px 4px;

  @media (min-width: ${theme.responsive.maxContentWidthPxVal}px) {
    right: 0px;
  }
`;

const DotIsNew = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${theme.colors.primaryBlue};
`;

const DotParent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: -16px;
  > div {
    margin-right: 8px;
  }
`;
const TextParent = styled.div`
  width: 100%;
`;

const ListItem = (props) => {
  const {
    category,
    title,
    content1,
    content2,
    note = { title: '', highlight: false },
    onChevronClick,
    isNotification = false,
    isActive = true,
    badgeTitle = 'COMPLETE',
    disabled,
    onRowClick = () => {},
    titleTop,
    is_new,
    view,
  } = props;

  return (
    <StyledListRow onClick={(event) => onRowClick(props, event)}>
      {is_new && view === 'chat' && (
        <DotParent>
          <DotIsNew />
        </DotParent>
      )}
      <TextParent>
        {titleTop && <TitleTop>{titleTop.toUpperCase()}</TitleTop>}
        <TitleContainer>
          <ElementTitle isNew={is_new && view === 'chat'} disabled={disabled}>
            {title}
          </ElementTitle>
          {disabled && <BadgeContainer>{badgeTitle}</BadgeContainer>}
        </TitleContainer>
        <ElementContent1 disabled={disabled}>{content1}</ElementContent1>
        <ElementContent2 disabled={disabled}>{content2}</ElementContent2>
        {note && note.title && <ElementNote highlight={note.highlight}>{note.title}</ElementNote>}
      </TextParent>
    </StyledListRow>
  );
};

export default ListItem;
