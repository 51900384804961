import { createSlice } from '@reduxjs/toolkit';
import {
  getSchedules,
  getScheduleDetails,
  changeScheduleDetailsStatus,
  postDelayInfo,
  postDoneInfo,
  getResidentNotes,
  getResidentNote,
  postResidentNote,
  patchResidentNote,
  postDonePickup,
  getServiceReceipt,
  patchServiceReceipt,
} from './schedule.actions';
import { RequestStatus } from '../helpers/RequestStatus';
import { toast } from 'react-toastify';

const slice = createSlice({
  name: 'residents',
  initialState: {
    fetchStatus: null,
    delayFetchErrorMessage: null,
    refetchTimestamp: null,
    list: [],
    details: null,
    serviceDetails: {
      delay: null,
      done: null,
      notes: null,
      // to detect go back on success
      delayedDoneSubmitStatus: null,
      notesSubmitStatus: null,
    },
    serviceReceipt: {
      receipt: {},
      costs: [],
      total: 0,
      fetchStatus: null,
      patchFetchStatus: null,
      errors: null,
    },
    postDoneInfoFetchStatus: null,
  },
  reducers: {
    updateSchedulesFetchStatus(state, action) {
      state.fetchStatus = action.payload;
    },
    clearScheduleDetails(state, action) {
      state.details = null;
    },
    setDelayedDoneStatus(state, action) {
      state.serviceDetails.delayedDoneSubmitStatus = action.payload;
    },
    setNotesStatus(state, action) {
      state.serviceDetails.notesSubmitStatus = action.payload;
    },
    setDelayFetchErrorMessage(state, action) {
      state.delayFetchErrorMessage = action.payload;
    },
    resetPatchServiceReceiptData(state, action) {
      state.serviceReceipt = {
        receipt: {},
        costs: [],
        total: 0,
        fetchStatus: null,
        patchFetchStatus: null,
        errors: null,
      };
    },
  },
  // don't have actions that can be exported, only respond to other
  extraReducers: {
    [getSchedules.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getSchedules.fulfilled]: (state, action) => {
      state.fetchStatus = RequestStatus.status.DONE;
      state.list = action.payload.data;
    },
    [getSchedules.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.status.ERROR;
      state.errorMessage = action.payload;
    },
    [getScheduleDetails.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getScheduleDetails.fulfilled]: (state, action) => {
      state.fetchStatus = RequestStatus.status.DONE;
      state.details = action.payload.data;
    },
    [getScheduleDetails.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.status.ERROR;
      state.errorMessage = action.payload;
    },
    [changeScheduleDetailsStatus.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [changeScheduleDetailsStatus.fulfilled]: (state, action) => {
      state.fetchStatus = RequestStatus.status.DONE;
      state.refetchTimestamp = Date.now();
      toast.info(action?.payload?.data?.message || 'Changes saved successfully', {
        autoClose: 3000,
      });
    },
    [changeScheduleDetailsStatus.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.status.ERROR;
      state.errorMessage = action.payload;
    },
    [postDelayInfo.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
      state.delayFetchErrorMessage = null;
    },
    [postDelayInfo.fulfilled]: (state, action) => {
      state.fetchStatus = RequestStatus.status.DONE;
      state.serviceDetails.delay = action.payload.data;
      toast.info(action?.payload?.data?.message || 'Changes saved successfully', {
        autoClose: 3000,
      });
      state.delayFetchErrorMessage = null;
      state.serviceDetails.delayedDoneSubmitStatus = RequestStatus.status.DONE;
    },
    [postDelayInfo.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.status.ERROR;
      state.delayFetchErrorMessage = action.payload?.errors;
      state.errorMessage = action.payload;
    },
    [postDoneInfo.pending]: (state, action) => {
      state.postDoneInfoFetchStatus = RequestStatus.status.FETCHING;
    },
    [postDoneInfo.fulfilled]: (state, action) => {
      state.postDoneInfoFetchStatus = RequestStatus.status.DONE;
      state.serviceDetails.done = action.payload.data;
      toast.info(action?.payload?.data?.message || 'Changes saved successfully', {
        autoClose: 3000,
      });
      state.serviceDetails.delayedDoneSubmitStatus = RequestStatus.status.DONE;
    },
    [postDoneInfo.rejected]: (state, action) => {
      state.postDoneInfoFetchStatus = RequestStatus.status.ERROR;
      state.errorMessage = action.payload;
    },
    [getResidentNotes.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getResidentNotes.fulfilled]: (state, action) => {
      state.fetchStatus = RequestStatus.status.DONE;
      state.serviceDetails.notes = action.payload.data;
    },
    [getResidentNotes.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.status.ERROR;
      state.errorMessage = action.payload;
    },
    [getResidentNote.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getResidentNote.fulfilled]: (state, action) => {
      state.fetchStatus = RequestStatus.status.DONE;
      state.serviceDetails.notes = [action.payload.data];
    },
    [getResidentNote.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.status.ERROR;
      state.errorMessage = action.payload;
    },
    [postResidentNote.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [postResidentNote.fulfilled]: (state, action) => {
      state.fetchStatus = RequestStatus.status.DONE;
      toast.info(action?.payload?.data?.message || 'Changes saved successfully', {
        autoClose: 3000,
      });
      state.serviceDetails.notesSubmitStatus = RequestStatus.status.DONE;
    },
    [postResidentNote.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.status.ERROR;
      state.errorMessage = action.payload;
    },
    [patchResidentNote.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [patchResidentNote.fulfilled]: (state, action) => {
      state.fetchStatus = RequestStatus.status.DONE;
      state.serviceDetails.notes[0] = action.payload.content;
      toast.info(action?.payload?.data?.message || 'Changes saved successfully', {
        autoClose: 3000,
      });
      state.serviceDetails.notesSubmitStatus = RequestStatus.status.DONE;
    },
    [patchResidentNote.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.status.ERROR;
      state.errorMessage = action.payload;
    },
    [postDonePickup.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [postDonePickup.fulfilled]: (state, action) => {
      state.fetchStatus = RequestStatus.status.DONE;
      state.refetchTimestamp = Date.now();
      toast.info(action?.payload?.data?.message || 'Changes saved successfully', {
        autoClose: 3000,
      });
    },
    [postDonePickup.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.status.ERROR;
      state.errorMessage = action.payload;
    },

    [getServiceReceipt.pending]: (state, action) => {
      state.serviceReceipt.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getServiceReceipt.fulfilled]: (state, action) => {
      const { receipt, costs, total } = action.payload.data;
      state.serviceReceipt.receipt = receipt;
      state.serviceReceipt.costs = costs;
      state.serviceReceipt.total = total;
      state.serviceReceipt.fetchStatus = RequestStatus.status.DONE;
    },
    [getServiceReceipt.rejected]: (state, action) => {
      state.serviceReceipt.fetchStatus = RequestStatus.status.ERROR;
      state.errors = action.payload;
    },

    [patchServiceReceipt.pending]: (state, action) => {
      state.serviceReceipt.patchFetchStatus = RequestStatus.status.FETCHING;
    },
    [patchServiceReceipt.fulfilled]: (state, action) => {
      state.serviceReceipt.patchFetchStatus = RequestStatus.status.DONE;
    },
    [patchServiceReceipt.rejected]: (state, action) => {
      state.serviceReceipt.patchFetchStatus = RequestStatus.status.ERROR;
      state.serviceReceipt.errors = action.payload.errors;
    },
  },
});

export const {
  updateSchedulesFetchStatus,
  clearScheduleDetails,
  setDelayedDoneStatus,
  setNotesStatus,
  setDelayFetchErrorMessage,
  resetPatchServiceReceiptData,
} = slice.actions;

export default slice.reducer;
