import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import { theme } from '../../theme';

const CircularIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinearProgressContainer = styled.div`
  .MuiLinearProgress-root {
    background-color: ${theme.colors.blueTint5};
  }
  .MuiLinearProgress-bar {
    background-color: ${theme.colors.blueTint3};
  }
`;

function LoadingIndicator(props) {
  const {
    type = 'circular' || 'linear',
    width = 40,
    height = 40,
    color = theme.colors.blueTint4,
  } = props;

  return (
    <>
      {type === 'circular' ? (
        <CircularIndicatorContainer>
          <CircularProgress color="secondary" style={{ width, height, color }} />
        </CircularIndicatorContainer>
      ) : (
        <LinearProgressContainer>
          <LinearProgress color="secondary" style={{ height: 3 }} />
        </LinearProgressContainer>
      )}
    </>
  );
}

export default LoadingIndicator;
