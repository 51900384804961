import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import _ from 'lodash';
import { covertObjToQueryString } from '../helpers/utilities';

export const getMoreUpdates = createAsyncThunk(
  'updates/getMoreUpdates',
  async (_data, thunkAPI) => {
    const { refetchAsFresh, forceQueryParams, ...data } = _data;
    const queryString = forceQueryParams
      ? forceQueryParams
      : _.isObject(data)
      ? covertObjToQueryString(data)
      : data;
    const response = await axios.get(`/updates/${queryString ? '?' + queryString : ''}`);
    return { refetchAsFresh, data: response.data };
  }
);

export const postCheckedUpdates = createAsyncThunk(
  'updates/postCheckedUpdates',
  async (ids, thunkAPI) => {
    const response = await axios.post(`/updates/mark_as_checked/`, { update_ids: ids });
    return response.data;
  }
);
