import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import auth from './reducers/auth.reducer';
import config from './reducers/config.reducer';
import residents from './reducers/residents.reducer';
import schedule from './reducers/schedule.reducer';
import chats from './reducers/chat.reducer';
import updates from './reducers/updates.reducer';
import services from './reducers/services.reducer';

const reducer = combineReducers({
  auth,
  config,
  residents,
  schedule,
  chats,
  updates,
  services,
});

const getMiddleware = () => {
  let middleware = [thunk];
  if (process.env.NODE_ENV === 'development') {
    middleware.push(logger);
  }
  return middleware;
};

export default createStore(reducer, applyMiddleware(...getMiddleware()));
