import React from 'react';
import styled from 'styled-components';
import LoadingIndicator from './LoadingIndicator';

export const LoadingIndicatorContainer = styled.div`
  height: 0px;
  position: relative;
`;

export const LoadingIndicatorContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: ${(props) => (props.type === 'linear' ? 5 : 55)}px;
  z-index: 100;
`;

const ListLoadingIndicator = (props) => {
  return (
    <LoadingIndicatorContainer>
      <LoadingIndicatorContentContainer type={props?.type}>
        <LoadingIndicator {...props} />
      </LoadingIndicatorContentContainer>
    </LoadingIndicatorContainer>
  );
};

export default ListLoadingIndicator;
