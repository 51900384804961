import axios from 'axios';
import _ from 'lodash';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { covertObjToQueryString } from '../helpers/utilities';
import ApiRequest from '../helpers/ApiRequest';

export const getSchedules = createAsyncThunk('residents/getSchedules', async (data, thunkAPI) => {
  const queryString = _.isObject(data) ? covertObjToQueryString(data) : data;
  const response = await axios.get(`/schedule/${queryString ? '?' + queryString : ''}`);
  return response.data;
});

export const getScheduleDetails = createAsyncThunk(
  'schedule/getScheduleDetails',
  async (params, thunkAPI) => {
    const { id, ...queryParams } = params;
    const queryString = _.isObject(queryParams) ? covertObjToQueryString(queryParams) : queryParams;
    const response = await axios.get(`/schedule/${id}/${queryString ? '?' + queryString : ''}`);
    return response.data;
  }
);

export const getResidentNotes = createAsyncThunk(
  'schedule/getResidentNotes',
  async (params, thunkAPI) => {
    const queryString = _.isObject(params) ? covertObjToQueryString(params) : params;
    const response = await axios.get(`/manager_notes/${queryString ? '?' + queryString : ''}`);
    return response.data;
  }
);

export const getResidentNote = createAsyncThunk(
  'schedule/getResidentNote',
  async (id, thunkAPI) => {
    const response = await axios.get(`/manager_notes/${id}/`);
    return response.data;
  }
);

export const patchResidentNote = createAsyncThunk(
  'schedule/patchResidentNote',
  async (data, thunkAPI) => {
    const { id, content } = data;
    const response = await axios.patch(`/manager_notes/${id}/`, { content });
    return response.data;
  }
);

// todo: mocked request
export const putResidentNote = createAsyncThunk(
  'schedule/putResidentNote',
  async (id, thunkAPI) => {
    const response = await axios.get(`/residents/${id}/`);
    return response.data;
  }
);

export const postResidentNote = createAsyncThunk(
  'schedule/postResidentNote',
  async (params, thunkAPI) => {
    const response = await axios.post(`/manager_notes/`, params);
    return response.data;
  }
);

export const postDelayInfo = createAsyncThunk('schedule/postDelayInfo', (data, thunkAPI) => {
  const { id, estimated_finish_date, delay_note, date, note, service_type } = data;
  return ApiRequest.post(`/scheduled_services/${id}/delay/`, {
    estimated_finish_date: estimated_finish_date || date,
    delay_note: delay_note || note,
    service_type: service_type,
  })(
    {
      estimated_finish_date: estimated_finish_date || date,
      delay_note: delay_note || note,
      service_type: service_type,
    },
    thunkAPI
  );
});

export const postDoneInfo = createAsyncThunk('schedule/postDoneInfo', async (data, thunkAPI) => {
  // const { id, receipt: _receipt, total, amount, imageId } = data;
  const { id, ...otherData } = data;
  // const receipt = +_receipt || +imageId;
  // const response = await axios.post(`/scheduled_services/${id}/done/`, {
  //   // receipt: receipt > 0 ? receipt : null,
  //   // total: +total || +amount,
  //     receipt, costs
  // });
  // return response.data;
  return ApiRequest.post(`/scheduled_services/${id}/done/`, {})(otherData, thunkAPI);
});

export const changeScheduleDetailsStatus = createAsyncThunk(
  'schedule/updateScheduleStatus',
  async (data, thunkAPI) => {
    const { id, status, queryParams } = data;
    const queryString = _.isObject(queryParams) ? covertObjToQueryString(queryParams) : queryParams;
    const response = await axios.post(
      `/schedule/${id}/update_status/${queryString ? '?' + queryString : ''}`,
      {
        status,
      }
    );
    return response.data;
  }
);

export const postDonePickup = createAsyncThunk(
  'schedule/postDonePickup',
  async (data, thunkAPI) => {
    const { id, queryParams = '' } = data;
    const queryString = _.isObject(queryParams) ? covertObjToQueryString(queryParams) : queryParams;
    const response = await axios.post(
      `/scheduled_services/${id}/done-pick-up/${queryString ? '?' + queryString : ''}`,
      {}
    );
    return response.data;
  }
);

export const getServiceReceipt = createAsyncThunk(
  `schedule/getServiceReceipt`,
  async (data, thunkAPI) => {
    const { id } = data;
    return ApiRequest.get(`/scheduled_services/${id}/receipt/`, {})({}, thunkAPI);
  }
);

export const patchServiceReceipt = createAsyncThunk(
  `schedule/patchServiceReceipt`,
  async (data, thunkAPI) => {
    const { id, params } = data;
    return ApiRequest.patch(`/scheduled_services/${id}/receipt/`, {})(params, thunkAPI);
  }
);
