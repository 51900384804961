import React from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

const NewServiceHeader = (props) => {
  const { title, loading } = props;

  return (
    <Box sx={{ pt: 2 }}>
      <Typography sx={{ mb: 2 }} variant={'h1'}>
        {title}
      </Typography>
      <Divider />
      <Box sx={{ height: '4px', mb: 1.5 }}>{loading && <LinearProgress />}</Box>
    </Box>
  );
};

export default NewServiceHeader;
