import { createTheme as createMuiTheme } from '@mui/material/styles';

export const theme = {
  colors: {
    darkGray: '#464E53',
    medGray: '#788087',
    medLightGray: '#CACACE',
    lightGray: '#F7F7F8',
    darkBlue: '#0A3B52',
    primaryBlue: '#0F5D82',
    blueTint2: '#0077AF',
    blueTint3: '#00A6D7',
    blueTint4: '#6BC9EB',
    blueTint5: '#E1F1F9',
    white: '#FFFFFF',
    black: '#000000',
    blackTheme: '#242729',
    badgeGray: '#E5E5E5',
  },
  fontSizes: {
    navbarLink: '11px',
    chats: '13px',
    small: '9px',
    regular: '13px',
    big: '18px',
  },
  fontWeights: {
    bold: 700,
  },
  header: {
    heightPxVal: 64,
    heightXSPxVal: 56,
  },
  footer: {
    heightPxVal: 64,
  },
  menu: {
    collapsedWidthPxVal: 60,
    bordersWidthPxVal: 5,
    expandedWidthPxVal: 240,
  },
  modals: {
    widthPxVal: 512,
    heightPxVal: 280,
  },
  input: {},
  radiuses: {},
  paddings: {
    appPaddingPxVal: 32,
    cardPaddingPxVal: 15,
    routeContentPxVal: 30,
  },
  margins: {
    appBarBottom: '10px',
    routeContentBottomPxVal: 30,
  },
  responsive: {
    maxContentWidthPxVal: 800,
  },
  sizes: {
    buttonHeightPxVal: 52,
    inputHeightPxVal: 40,
    checkboxDimPxVal: 26,
    avatarDimPxVal: 33,
    navbarHeightPxVal: 64,
    navbarHeightSPxVal: 48,
    bottomBarHeightPxVal: 56,
    drawerHeaderHeightPxVal: 72,
  },
  devices: {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
  },
};

const black = {
  main: theme.colors.black,
  contrastText: theme.colors.white,
};

const primary = {
  main: theme.colors.blueTint2,
  light: theme.colors.blueTint4,
  dark: theme.colors.darkBlue,
  contrastText: theme.colors.white,
};

const text = {
  primary: theme.colors.darkGray,
  secondary: '#7c878e',
  disabled: 'rgba(55, 76, 81, 0.48)',
};

const error = {
  main: '#D14343',
  light: '#DA6868',
  dark: '#922E2E',
  contrastText: '#FFFFFF',
};

export const materialTheme = createMuiTheme({
  palette: {
    primary,
    text,
    error,
    black,
  },
  typography: {
    color: theme.colors.darkGray,
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
    ].join(','),
    h1: {
      flex: 1,
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '36px',
      letterSpacing: '0.17499999701976776px',
      textAlign: 'left',
      userSelect: 'none',
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          '&.variant-backgroundFilled': {
            backgroundColor: theme.colors.lightGray,
            mx: 1,
            '& fieldset': {
              borderRadius: '8px',
              border: 'unset',
              // background: theme.colors.lightGray,
            },
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: `solid 2px ${theme.colors.blueTint2} !important`,
            },
          },
          '&.Mui-error': {
            '& fieldset': {
              color: error.main,
              border: `solid 2px ${error.main} !important`,
              borderRadius: '8px',
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          letterSpacing: '0.15em',
        },
        outlined: {
          letterSpacing: '0.15em',
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

// export const materialTheme = createTheme(
//   adaptV4Theme({
//     palette: {
//       primary: {
//         // main: theme.colors.black,
//         main: theme.colors.blueTint2,
//       },
//       secondary: { main: theme.colors.black },
//       text: { primary: theme.colors.darkGray },
//     },
//     // status: {},
//     typography: {
//       color: theme.colors.darkGray,
//       fontFamily: [
//         'Poppins',
//         '-apple-system',
//         'BlinkMacSystemFont',
//         '"Segoe UI"',
//         'Roboto',
//         '"Helvetica Neue"',
//         'Arial',
//         'sans-serif',
//         '"Apple Color Emoji"',
//         '"Segoe UI Emoji"',
//         '"Segoe UI Symbol"',
//       ].join(','),
//     },
//     // multilineColor: {
//     //   color: theme.colors.creditDarkBlue,
//     // },
//   })
// );

// based on https://jsramblings.com/how-to-use-media-queries-with-styled-components/
export const device = {
  mobileS: `(min-width: ${theme.devices.mobileS})`,
  mobileM: `(min-width: ${theme.devices.mobileM})`,
  mobileL: `(min-width: ${theme.devices.mobileL})`,
  tablet: `(min-width: ${theme.devices.tablet})`,
  laptop: `(min-width: ${theme.devices.laptop})`,
  laptopL: `(min-width: ${theme.devices.laptopL})`,
  desktop: `(min-width: ${theme.devices.desktop})`,
  desktopL: `(min-width: ${theme.devices.desktop})`,
};
