import React, { useEffect, useState } from 'react';
import store from '../../store';
import NetworkService from '../../helpers/network.service';
import { RequestStatus } from '../../helpers/RequestStatus';
import { getLoginPath } from '../../routes';
import { useHistory } from 'react-router-dom';

function SyncApp(props) {
  const {
    children,
    token,
    syncTokenWithStore,
    fetchStatus,
    getAuthInfo,
    userRole,
    getDropdownsChoices,
  } = props;

  const [wasSynced, setWasSynced] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (
      wasSynced &&
      token &&
      !RequestStatus.isFetching(fetchStatus) &&
      !RequestStatus.isDone(fetchStatus)
    ) {
      NetworkService.setupDefaultHeaders(token);
      getAuthInfo();
      getDropdownsChoices();
    } else if (!token && wasSynced) {
      history.push(getLoginPath());
    }
  }, [token, wasSynced]);

  useEffect(() => {
    if (!token) syncTokenWithStore();
    NetworkService.syncHeaderTokenWithStorage();
    NetworkService.setupInterceptors(store, history);
    setWasSynced(true);
  }, []);

  return token ? children : <div></div>;
}

export default SyncApp;
