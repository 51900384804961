import styled from 'styled-components';
import { theme } from '../theme';

export const ViewContainer = styled.div`
  height: ${({ showActionButton }) =>
    showActionButton ? 'calc(100% - 68px)' : '100%'}; //add service button height
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: ${theme.responsive.maxContentWidthPxVal}px;
  margin-left: auto;
  margin-right: auto;
`;

// limits only width, used for absolute loading indicator
export const ViewLimiter = styled.div`
  width: 100%;
  position: relative;
  max-width: ${theme.responsive.maxContentWidthPxVal}px;
  margin-left: auto;
  margin-right: auto;
`;

export const HeaderContainer = styled.div`
  overflow: hidden;
  min-height: 36px;
`;

export const ContentContainer = styled.div`
  margin-top: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: -${theme.paddings.appPaddingPxVal}px;
  padding-right: ${theme.paddings.appPaddingPxVal}px;
  min-height: 30px;
  //&::-webkit-scrollbar {
  //  display: none;
  //}
  //scrollbar-width: none;
`;

export const ListsContainer = styled.div`
  padding-top: 40px;
  // padding-bottom: ${theme.footer.heightPxVal}px;
  // height: 100%;
  // > div {
  //   height: 100%;
  //   overflow: auto;
  // }
`;
