const getErrorValue = (error) => {
  if (Array.isArray(error)) return error[0];
  else return error;
};

export const setFormErrors = (errors, setError, isFieldArray, errorKey) => {
  if (!Object.keys(errors).length) return;
  if (isFieldArray) {
    errors.map((error, idx) => {
      Object.entries(error).map(([key, values]) => {
        setError(`${errorKey}.${idx}.${key}`, { type: 'custom', message: getErrorValue(values) });
      });
    });
  } else
    Object.entries(errors).map(([key, values]) =>
      setError(key, { type: 'custom', message: getErrorValue(values) })
    );
};
