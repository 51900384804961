import React, { useEffect, useState } from 'react';
import { Box, ButtonGroup } from '@mui/material';
import Button from '@mui/material/Button';

const InputSingleChoiceButtons = (props) => {
  const { options, value, onChange } = props;

  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    onChange(internalValue);
  }, [internalValue]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: 2,
      }}
    >
      {options.length > 0 &&
        options.map((option) => (
          <Button
            fullWidth
            variant={option.value === internalValue ? 'contained' : 'outlined'}
            color={'primary'}
            onClick={() =>
              setInternalValue((prev) => (prev === option.value ? null : option.value))
            }
            sx={{ letterSpacing: 0, textTransform: 'none' }}
          >
            {option?.value_display || option?.label}
          </Button>
        ))}
    </Box>
  );
};

export default InputSingleChoiceButtons;
