import React from 'react';
import AppBar from '../../components/AppBar/AppBar';
import { ContentContainer, RouteContent } from './Layout';
import {
  getLoginPath,
  getRoutesForRoutingComponent,
  getRoutesForUnauthRoutingComponent,
} from '../../routes';

const UnauthLayout = (props) => {
  const { appTitle, path = getLoginPath(), onLoginClick } = props;

  const route = getRoutesForUnauthRoutingComponent().find((element) => element.path === path);

  return (
    <ContentContainer>
      <AppBar elevation={0} title={appTitle} onLoginClick={onLoginClick} {...route?.appBar} />
      <RouteContent className="route-content">
        <route.component {...props} />
      </RouteContent>
    </ContentContainer>
  );
};

export default UnauthLayout;
