import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { covertObjToQueryString } from '../helpers/utilities';
import axios from 'axios';

export const getServices = createAsyncThunk('services/getServices', async (data, thunkAPI) => {
  const queryString = _.isObject(data) ? covertObjToQueryString(data) : data;
  const response = await axios.get(`/scheduled_services/${queryString ? '?' + queryString : ''}`);
  return response.data;
});

export const postServiceComplete = createAsyncThunk(
  'services/postServiceComplete',
  async (data, thunkAPI) => {
    const { apartmentId, is_confirmed } = data;
    const response = await axios.post(`/scheduled_services/${apartmentId}/change_confirmed/`, {
      is_confirmed: !is_confirmed,
    });
    return response.data;
  }
);

export const postAndGetServiceComplete = (data) => async (dispatch) => {
  const { postData, getData } = data;
  await dispatch(postServiceComplete(postData));
  return await dispatch(getServices(getData));
};
