import React, { useEffect } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import { getRoutesForBottomNavigation } from '../../routes';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import styled from 'styled-components';
import { theme } from '../../theme';
import { Badge } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/styles';

const StyledBadge = withStyles((muiTheme) => ({
  badge: {
    right: -12,
    top: 9,
    border: `2px solid ${theme.colors.blueTint2}`,
    backgroundColor: theme.colors.blueTint2,
    padding: '0 4px',
    color: theme.colors.white,
    fontWeight: 700,
    borderRadius: '4px',
  },
}))(Badge);

const BottomNavigationContainer = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 9990;
  max-height: ${theme.footer.heightPxVal};
  border-top: ${({ borderColor }) => `solid 1px ${borderColor}`};
  svg * {
    stroke: ${theme.colors.medGray};
    stroke-width: 2;
  }

  .MuiBottomNavigationAction-label {
    margin-top: 5px;
    font-size: 12px;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.15em;
    text-align: center;
    font-weight: 400;
  }

  .Mui-selected {
    svg * {
      stroke: ${theme.colors.darkGray};
      stroke-width: 2;
    }
    .MuiBottomNavigationAction-label {
      margin-top: 4px;
      font-size: 12px;
      font-style: normal;
      line-height: 18px;
      letter-spacing: 0.15em;
      text-align: center;
      font-weight: 600;
      color: ${theme.colors.darkGray};
    }
  }
`;

const CustomBottomNavigation = (props) => {
  const { bottomNavigationValue, setBottomNavigationValue, menuBadges } = props;

  let location = useLocation();
  const MUItheme = useTheme();

  const getBadgeForRoute = (bottomRoute) => {
    if (!bottomRoute.badgeKey) return;
    let nr = 0;
    for (let badgeNrKey of Object.keys(menuBadges)) {
      if (badgeNrKey === bottomRoute.badgeKey) {
        nr = menuBadges[badgeNrKey];
        break;
      }
    }
    return nr;
  };

  useEffect(() => {
    let value = 0;
    const routesBottomNav = getRoutesForBottomNavigation();
    const fullPath = location.pathname;

    for (const routeIdx in routesBottomNav) {
      if (routesBottomNav[routeIdx]?.path === '/') continue;
      else if (
        routesBottomNav.hasOwnProperty(routeIdx) &&
        fullPath.includes(routesBottomNav[routeIdx]?.path)
      ) {
        value = routeIdx;
        break;
      }
    }
    setBottomNavigationValue({
      value: parseInt(value),
      bottomNavigationRoutes: getRoutesForBottomNavigation(),
      noHistoryPush: true,
    });
  }, []);

  return (
    <BottomNavigationContainer borderColor={MUItheme.palette.divider}>
      <BottomNavigation
        value={bottomNavigationValue}
        onChange={(event, value) =>
          setBottomNavigationValue({
            event,
            value,
            bottomNavigationRoutes: getRoutesForBottomNavigation(),
          })
        }
        showLabels
      >
        {getRoutesForBottomNavigation().map((bottomRoute) => {
          const badgeNr = getBadgeForRoute(bottomRoute);
          return (
            <BottomNavigationAction
              label={<StyledBadge badgeContent={badgeNr}>{bottomRoute.label}</StyledBadge>}
              icon={bottomRoute.icon}
              key={bottomRoute.label}
            />
          );
        })}
      </BottomNavigation>
    </BottomNavigationContainer>
  );
};

export default CustomBottomNavigation;
