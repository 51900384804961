export const HomeIcon = () => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.5 7.5V18H8M15.5 7.5V18H11M1 9.5L9.5 1L18 9.5M8 18V13.5H11V18M8 18H11"
      stroke="#788087"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const ChecklistIcon = () => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="8.5"
      y="4.5"
      width="9"
      height="1"
      rx="0.5"
      stroke="#788087"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect
      x="8.5"
      y="13.5"
      width="9"
      height="1"
      rx="0.5"
      stroke="#788087"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 4L3 6L6 1"
      stroke="#788087"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 13L3 15L6 10"
      stroke="#788087"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const ChatIcon = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 1L16 1V11.2778L5.26313 11.2778C4.74245 11.2778 4.2423 11.4808 3.86897 11.8438L1 14.6331L1 1Z"
      stroke="#788087"
      stroke-width="2"
      stroke-linejoin="round"
    />
  </svg>
);

export const BellIcon = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.5 2.5C2.13636 2.5 6 13 1 13H16C11 13 14.8636 2.5 8.5 2.5Z"
      stroke="#788087"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 13.5C11 14.8807 9.88071 16 8.5 16C7.11929 16 6 14.8807 6 13.5"
      stroke="#788087"
      stroke-width="2"
    />
    <rect x="7" width="3" height="3" rx="1.5" fill="#788087" />
  </svg>
);
