import React, { useEffect, useState } from 'react';
import { Box, Avatar } from '@mui/material';
import Typography from '@mui/material/Typography';
import PetsIcon from '@mui/icons-material/Pets';
import { useTheme } from '@mui/styles';

const InputPetSelect = (props) => {
  const { value, onChange, options } = props;

  const theme = useTheme();

  const [selectedPet, setSelectedPet] = useState(value?.toString() ?? null);

  useEffect(() => {
    onChange(selectedPet ? [+selectedPet] : null);
  }, [selectedPet]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        // justifyContent: 'space-between',
        gap: 2,
        px: '3px',
      }}
    >
      {options.length > 0 ? (
        options.map((option) => {
          const { pet } = option;
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                alignItems: 'center',
                maxWidth: '64px',
              }}
              onClick={() =>
                setSelectedPet((prev) => (prev === option.value ? null : option.value))
              }
            >
              <Avatar
                {...(pet?.avatar && { src: pet.avatar.original })}
                sx={{
                  ...(selectedPet === option.value && {
                    outline: `solid 3px ${theme.palette.primary.main}`,
                  }),
                  width: '64px',
                  height: '64px',
                }}
              >
                {!pet?.avatar && <PetsIcon />}
              </Avatar>
              <Typography
                variant={'subtitle2'}
                sx={{
                  ...(selectedPet === option.value && { fontWeight: 'bold' }),
                }}
              >
                {pet?.name}
              </Typography>
            </Box>
          );
        })
      ) : (
        <Typography variant={'subtitle2'}>No pet profiles found</Typography>
      )}
    </Box>
  );
};

export default InputPetSelect;
