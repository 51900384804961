import axios from 'axios';
import _ from 'lodash';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { covertObjToQueryString } from '../helpers/utilities';

export const getChats = createAsyncThunk('chat/getChats', async (data, thunkAPI) => {
  const queryString = _.isObject(data) ? covertObjToQueryString(data) : data;
  const response = await axios.get(`/residents/chat/${queryString ? '?' + queryString : ''}`);
  return response.data;
});
