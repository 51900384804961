import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import * as serviceWorker from '../serviceWorkerRegistration';
import SnackbarContent from '@mui/material/SnackbarContent';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    top: 0,
    zIndex: 9999,
  },
});

const SWSnackbar = (props) => {
  const [isNewSW, setIsNewSW] = useState({
    newVersionAvailable: false,
    waitingWorker: {},
  });

  const classes = useStyles();

  const onServiceWorkerUpdate = (registration) => {
    console.log('SW should be updated!');
    setIsNewSW({
      waitingWorker: registration && registration.waiting,
      newVersionAvailable: true,
    });
  };

  const updateServiceWorker = () => {
    const { waitingWorker } = isNewSW;
    waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    setIsNewSW({ newVersionAvailable: false });
    window.location.reload();
  };

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      serviceWorker.register({ onUpdate: onServiceWorkerUpdate });
    }
  }, []);

  return (
    <>
      {isNewSW.newVersionAvailable && (
        <SnackbarContent
          classes={{ root: classes.root }}
          message="New version available"
          action={
            <Button size="small" variant="contained" onClick={updateServiceWorker}>
              Update
            </Button>
          }
        />
      )}
    </>
  );
};

export default SWSnackbar;
