import { createSlice } from '@reduxjs/toolkit';
import { format, addDays } from 'date-fns';
import { RequestStatus } from '../helpers/RequestStatus';
import { getDropdownsChoices, getMenuBadges } from './config.actions';

const getWeekdaysDropdownChoices = () => {
  let choices = [];
  let i = 0;
  const anArbitrarySunday = new Date(2017, 9, 23);
  while (i < 7) {
    const newVal = {
      value: i,
      label: format(addDays(anArbitrarySunday, i), 'EEEE'),
    };
    choices.push(newVal);
    i++;
  }
  return choices;
};

const auth = createSlice({
  name: 'config',
  initialState: {
    fetchStatus: null,
    dropdownChoices: {},
    constants: {},
    menuBadges: {},
  },
  reducers: {
    updateConfigFetchStatus(state, action) {
      state.buildingsFetchStatus = action.payload;
    },
  },
  // don't have actions that can be exported, only respond to other
  extraReducers: {
    [getDropdownsChoices.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getDropdownsChoices.fulfilled]: (state, action) => {
      state.constants = { ...action.payload[0].data?.constants, buildings: action.payload[1].data };
      state.dropdownChoices = {
        buildings: state.constants.buildings.map((building) => ({
          value: building.id,
          label: building.name,
        })),
        weekdays: getWeekdaysDropdownChoices(),
        timesOfTheDay: Object.entries(state.constants.times_of_the_day).map(([value, label]) => ({
          value,
          label,
        })),
      };
      state.fetchStatus = RequestStatus.status.DONE;
    },
    [getDropdownsChoices.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.status.ERROR;
      state.errorMessage = action.payload;
    },
    [getMenuBadges.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getMenuBadges.fulfilled]: (state, action) => {
      state.menuBadges = action.payload.data;
      state.fetchStatus = RequestStatus.status.DONE;
    },
    [getMenuBadges.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.status.ERROR;
      state.errorMessage = action.payload;
    },
  },
});

export const { updateConfigFetchStatus } = auth.actions;

export default auth.reducer;
