import { theme } from '../theme';
import styled, { css } from 'styled-components';

const Avatar = styled.img`
  width: ${theme.sizes.avatarDimPxVal}px;
  height: ${theme.sizes.avatarDimPxVal}px;
  border-radius: 50%;

  ${({ active }) =>
    active &&
    css`
      border: 2px solid ${theme.colors.blueTint2};
    `}
`;

export default Avatar;
