import { createSlice } from '@reduxjs/toolkit';
import { getMoreUpdates, postCheckedUpdates } from './updates.actions';
import { RequestStatus } from '../helpers/RequestStatus';
import { toast } from 'react-toastify';

const slice = createSlice({
  name: 'updates',
  initialState: {
    fetchStatus: null,
    list: [],
    pagesFetched: 0,
    areMoreUpdatesToFetch: false,
    refetchAsFresh: false,
    pagination: {
      count: 0,
      next: null,
      previous: null,
    },
    // used only after post to refetch all as new
    refetchChangesTimestamp: null,
  },
  reducers: {
    updateFetchStatus(state, action) {
      state.fetchStatus = action.payload;
    },
    clearUpdates(state, action) {
      state.list = [];
      state.areMoreUpdatesToFetch = false;
      state.pagesFetched = 0;
      state.pagination = {
        count: 0,
        next: null,
        previous: null,
      };
    },
  },
  // don't have actions that can be exported, only respond to other
  extraReducers: {
    [getMoreUpdates.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
      state.refetchAsFresh = false;
    },
    [getMoreUpdates.fulfilled]: (state, action) => {
      const { refetchAsFresh, noPagination = false, data } = action.payload;
      state.fetchStatus = RequestStatus.status.DONE;
      if (data?.data?.count) {
        state.pagination = {
          count: data?.data?.count,
          next: data?.data?.next,
          nextQueryParams: data?.data?.next ? data?.data?.next.split('?')[1] : null,
          previous: data?.data?.previous,
        };
      }
      state.refetchAsFresh = refetchAsFresh;
      state.list = refetchAsFresh ? data.data.results : [...state.list, ...data.data.results];
    },
    [getMoreUpdates.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.status.ERROR;
      state.errorMessage = action.payload;
    },
    [postCheckedUpdates.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [postCheckedUpdates.fulfilled]: (state, action) => {
      clearUpdates();
      state.fetchStatus = RequestStatus.status.DONE;
      state.refetchChangesTimestamp = Date.now();
      toast.info(
        action?.payload?.data?.message || action?.payload?.data || 'Changes saved successfully',
        {
          autoClose: 3000,
        }
      );
    },
    [postCheckedUpdates.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.status.ERROR;
      state.errorMessage = action.payload;
    },
  },
});

export const { clearUpdates, updateFetchStatus } = slice.actions;

export default slice.reducer;
