import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getSessionToken } from '../helpers/utilities';
import NetworkService from '../helpers/network.service';
import { unauthUser, updateSessionToken } from './auth.reducer';
import ApiRequest from '../helpers/ApiRequest';

export const signIn = createAsyncThunk('auth/signIn', ApiRequest.post('/auth/sign-in/'));

export const signUp = createAsyncThunk('auth/signUp', async (data, thunkAPI) => {
  const { full_name, email, password, building_address, apartment } = data;
  const response = await axios.post('/auth/sign-up/', data);
  return response.data;
});

export const resetPassword = createAsyncThunk('auth/resetPassword', async (data, thunkAPI) => {
  const { email } = data;
  const response = await axios.post('/auth/auth/password-reset-request/', data);
  return response.data;
});

// duplicated in config.actions.js
export const signOut = createAsyncThunk('auth/signOut', async (data, thunkAPI) => {
  const response = await axios.delete('/auth/sign-out/');
  return response.data;
});

export const getAuthInfo = createAsyncThunk('auth/getAuthInfo', async (data, thunkAPI) => {
  const response = await axios.get('/auth/me/');
  return response.data;
});

export const syncTokenWithStore = createAsyncThunk(
  'auth/syncTokenWithStore',
  async (data, thunkAPI) => {
    const { dispatch } = thunkAPI;
    let sessionToken = getSessionToken();

    if (!sessionToken || sessionToken === 'null') {
      dispatch(unauthUser());
    } else {
      NetworkService.syncHeaderTokenWithStorage();
      dispatch(updateSessionToken(sessionToken));
    }
  }
);
