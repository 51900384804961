import React from 'react';
import loadable from '@loadable/component';
import { HomeIcon, ChatIcon, ChecklistIcon, BellIcon } from './components/BottomNavigationIcons';
import PredefinedService from './containers/NewService/PredefinedService/PredefinedService';
import CustomService from './containers/NewService/CustomService/CustomService';
import PredefinedServicePreferences from './containers/NewService/PredefinedService/PredefinedServicePreferences';

const BarProfileImg = loadable(() => import('./components/BarProfileImg'));
const Home = loadable(() => import('./containers/Home'));
const Chats = loadable(() => import('./containers/Chats'));
const ChatDetails = loadable(() => import('./containers/Chat'));
const Login = loadable(() => import('./containers/Login'));
const ScheduleDetails = loadable(() => import('./containers/ApartmentDetails'));
const Notes = loadable(() => import('./containers/Notes'));
const Residents = loadable(() => import('./containers/Residents'));
const DelayedDone = loadable(() => import('./containers/DelayedDone'));
const Services = loadable(() => import('./containers/Services'));
const Updates = loadable(() => import('./containers/Updates'));
const NewService = loadable(() => import('./containers/NewService'));

export const getUnauthPath = () => '/login';
export const getHomePath = () => '/';
export const getCompletePath = () => '/complete';
export const getChatPath = () => '/chat';
export const getNewChatPath = () => '/new-chat';
export const getLoginPath = () => '/login';
export const getResidentsPath = () => '/residents';
export const getNotesPath = () => '/notes';
export const getSchedulePath = () => '/schedule';
export const getServicesPath = () => '/services';
export const getUpdatesPath = () => '/updates';
export const getDelayedNotePath = () => '/delayed-note';
export const getDoneNotePath = () => '/done-note';
export const getNewServicePath = (step = null) => `/new-service${step ? `/${step}` : ''}`;
export const getNewCustomServicePath = () => `/new-custom-service`;
export const getNewPredefinedServicePath = () => `/new-predefined-service`;
export const getNewPredefinedServicePreferencesPath = () =>
  `${getNewPredefinedServicePath()}/preferences`;

export const routes = [
  {
    path: `${getHomePath()}`,
    exact: true,
    label: 'SCHEDULE',
    component: Home,
    icon: <HomeIcon />,
    iconRotation: 0,
    order: 0,
    isBottomMenu: true,
    appBar: {
      title: <BarProfileImg />,
      actionButtonLabel: '',
      navigationType: 'menu',
      // clickRoute: getResidentsPath(),
    },
  },
  {
    path: `${getServicesPath()}`,
    exact: true,
    label: 'SERVICES',
    component: Services,
    icon: <ChecklistIcon />,
    iconRotation: 0,
    order: 0,
    isBottomMenu: true,
    badgeKey: 'services',
    appBar: {
      actionButtonLabel: '',
      navigationType: 'menu',
      // clickRoute: getResidentsPath(),
    },
  },
  {
    path: `${getUpdatesPath()}`,
    exact: true,
    label: 'UPDATES',
    component: Updates,
    icon: <BellIcon />,
    iconRotation: 0,
    order: 0,
    isBottomMenu: true,
    badgeKey: 'updates',
    appBar: {
      title: <div>Updates</div>,
      navigationType: null,
      actionButtonLabel: 'COMPLETE',
      onActionButtonClick: () => {},
    },
  },
  {
    path: `${getChatPath()}`,
    exact: true,
    label: 'CHAT',
    component: Chats,
    icon: <ChatIcon />,
    iconRotation: 0,
    order: 0,
    isBottomMenu: true,
    badgeKey: 'chats',
    appBar: {
      title: <div>Chats</div>,
      navigationType: null,
      actionButtonLabel: 'NEW',
    },
  },
  {
    path: `${getNewServicePath()}`,
    exact: true,
    component: NewService,
    order: 0,
    appBar: {
      // title: 'New Service',
      title: '',
      actionButtonLabel: '',
      navigationType: 'back',
    },
  },
  {
    path: `${getNewServicePath()}/:step`,
    exact: true,
    component: NewService,
    order: 0,
    appBar: {
      // title: 'New Service',
      title: '',
      actionButtonLabel: '',
      navigationType: 'back',
    },
  },
  {
    path: `${getNewCustomServicePath()}`,
    exact: true,
    component: CustomService,
    order: 0,
    appBar: {
      // title: 'New Service',
      title: '',
      actionButtonLabel: '',
      navigationType: 'back',
    },
  },
  {
    path: `${getNewPredefinedServicePath()}`,
    exact: true,
    component: PredefinedService,
    order: 0,
    appBar: {
      // title: 'New Service',
      title: '',
      actionButtonLabel: '',
      navigationType: 'back',
    },
  },
  {
    path: `${getNewPredefinedServicePreferencesPath()}`,
    exact: true,
    component: PredefinedServicePreferences,
    order: 0,
    appBar: {
      // title: 'New Service',
      title: '',
      actionButtonLabel: '',
      navigationType: 'back',
    },
  },
  {
    path: `${getChatPath()}/:id`,
    exact: true,
    component: ChatDetails,
    order: 0,
    appBar: {
      title: 'Chat',
      actionButtonLabel: '',
      navigationType: 'back',
    },
  },
  {
    path: `${getNewChatPath()}`,
    exact: true,
    component: Residents,
    order: 0,
    appBar: {
      title: 'My Residents',
      actionButtonLabel: '',
      navigationType: 'back',
    },
  },
  {
    path: `${getResidentsPath()}`,
    exact: true,
    label: 'MY RESIDENTS',
    component: Residents,
    order: 0,
    isDrawerMenu: true,
    appBar: {
      title: 'My Residents',
      actionButtonLabel: '',
      navigationType: 'back',
    },
  },
  {
    path: `${getSchedulePath()}/:id`,
    exact: true,
    component: ScheduleDetails,
    order: 0,
    appBar: {
      title: 'Apartment Details',
      actionButtonLabel: '',
      navigationType: 'back',
    },
  },
  // the same, but new url, because of client's confusion
  {
    path: `${getResidentsPath()}/:id`,
    exact: true,
    component: ScheduleDetails,
    order: 0,
    appBar: {
      title: 'Resident Details',
      actionButtonLabel: '',
      navigationType: 'back',
    },
  },
  {
    path: `${getNotesPath()}/:id`,
    exact: true,
    label: 'Notes',
    component: Notes,
    isBottomMenu: false,
    appBar: {
      title: 'Notes',
      actionButtonLabel: '',
      navigationType: 'back',
    },
  },
  {
    path: `${getDelayedNotePath()}/:id`,
    exact: true,
    component: DelayedDone,
    isBottomMenu: false,
    appBar: {
      title: 'Service Delayed',
      actionButtonLabel: '',
      navigationType: 'back',
    },
  },
  {
    path: `${getDoneNotePath()}/:id`,
    exact: true,
    component: DelayedDone,
    isBottomMenu: false,
    appBar: {
      title: 'Service Complete',
      actionButtonLabel: '',
      navigationType: 'back',
    },
  },
];

export const unauthRoutes = [
  {
    path: `${getLoginPath()}`,
    exact: true,
    component: Login,
    appBar: {
      title: <BarProfileImg />,
      actionButtonLabel: '',
      navigationType: 'back',
    },
  },
];

export const getRoutesForRoutingComponent = () => {
  return routes.reduce(
    (arr, elem) => (elem.childRoutes ? [...arr, elem, ...elem.childRoutes] : [...arr, elem]),
    []
  );
};
export const getRoutesForUnauthRoutingComponent = () => {
  return unauthRoutes;
};
export const getRoutesForBreadcrumb = getRoutesForRoutingComponent;
export const getRoutesForMenu = () => {};
export const getRoutesForBottomNavigation = () => routes.filter((route) => route.isBottomMenu);
export const getRoutesForDrawerNavigation = () => routes.filter((route) => route.isDrawerMenu);
