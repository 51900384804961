import axios from 'axios';
import { toast } from 'react-toastify';

const handleRequest = (request, thunkApi) =>
  request
    .then((response) => response.data)
    .catch((error) => thunkApi.rejectWithValue(error?.response?.data || error));

const buildUrl = (url, params) => {
  // Support URLs with named identifiers, such as '/posts/get/:id'.
  // This code replaces the ':id' part with the value of params.id
  Object.keys(params).forEach((k) => {
    if (url.indexOf(':' + k) > -1) {
      url = url.replace(':' + k, params[k]);
      delete params[k];
    }
  });

  // all the parameters that were not bound to a named identifier are appended to the URL
  const encoded = Object.entries(params)
    .map((kv) => kv.map(encodeURIComponent).join('='))
    .join('&');
  return url + (encoded.length > 0 ? '?' + encoded : '');
};

/**
 * Class based on createAsyncThunk issue https://github.com/reduxjs/redux-toolkit/issues/390
 */
export default class ApiRequest {
  static post = (url, axiosConfig = {}) => (obj = {}, thunkApi) =>
    handleRequest(axios.post(url, obj, axiosConfig), thunkApi);

  static patch = (url, axiosConfig = {}) => (obj = {}, thunkApi) =>
    handleRequest(axios.patch(url, obj, axiosConfig), thunkApi);

  static put = (url, axiosConfig = {}) => (obj = {}, thunkApi) =>
    handleRequest(axios.put(url, obj, axiosConfig), thunkApi);

  static get = (url, axiosConfig = {}) => (obj = {}, thunkApi) =>
    handleRequest(axios.get(buildUrl(url, obj), axiosConfig), thunkApi);

  static delete = (url, axiosConfig = {}) => (obj = {}, thunkApi) =>
    handleRequest(axios.delete(buildUrl(url, obj), axiosConfig), thunkApi);
}

export const showErrorMessagesForAction = (action, forcedMessage) => {
  if (!action?.payload?.errors) {
    console.error('No errors to extract from action');
    return;
  }
  if (forcedMessage)
    toast.error(typeof forcedMessage ? `${forcedMessage}` : 'Error', {
      autoClose: 3000,
    });
  else
    try {
      Object.entries(action?.payload?.errors).forEach((error) => {
        toast.error(typeof error[1] === 'object' ? `${error[1][0]}` : error[1] || 'Error', {
          autoClose: 3000,
        });
      });
    } catch (e) {
      console.error(e);
      toast.error('Unexpected error', {
        autoClose: 3000,
      });
    }
};
