import {
  Autocomplete,
  Card,
  Checkbox,
  Chip,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { RequestStatus } from '../../../helpers/RequestStatus';
import { useTheme } from '@mui/styles';
import { compact } from 'lodash';

const InputComboBox = (props) => {
  const {
    options,
    onChange,
    noValueMessage,
    isTouched,
    defaultValue = null,
    label = null,
    placeholder = null,
    fetchStatus = null,
    disabled = false,
    error,
    inputRef,
    value: valueToForceSet,
    requiredSign = false,
    multiple = false,
    limitTags = null,
    sx = {},
    withCheckboxes = false,
    className = null,
    ...other
  } = props;

  const MUItheme = useTheme();

  const [value, setValue] = useState(valueToForceSet ?? multiple ? [] : null);
  const [inputValue, setInputValue] = useState(null);
  const [shouldSetDefaultValue, setShoudSetDefaultValue] = useState(true);

  const handleOnValueChange = (e, value) => {
    setValue(value);
    if (multiple) {
      onChange({ ...e, target: { ...e.target, value: value?.map((_v) => _v?.value) } });
    } else {
      onChange({ ...e, target: { ...e.target, value: value?.value } });
    }
  };

  const handleOnInputValueChange = (e, value, reason) => {
    setInputValue(value);
  };

  useEffect(() => {
    if ((!!defaultValue || defaultValue === 0) && options.length > 0 && shouldSetDefaultValue) {
      if (multiple && Array.isArray(defaultValue)) {
        setValue(
          compact(
            defaultValue.map((val) =>
              options.find(
                (option) => option.value === (val.hasOwnProperty('value') ? val.value : val)
              )
            )
          )
        );
      } else {
        setValue(options.find((option) => option.value === defaultValue));
      }
      setShoudSetDefaultValue(false);
    }
  }, [defaultValue, options]);

  useEffect(() => {
    if (RequestStatus.isFetching(fetchStatus)) {
      setShoudSetDefaultValue(true);
      setValue(null);
      setInputValue(null);
    }
    if (RequestStatus.isDone(fetchStatus) && value) {
      setShoudSetDefaultValue(false);
    }
  }, [fetchStatus, value]);

  // useEffect(() => {
  //   if (options) {
  //     let newVal =
  //       options.find((option) => option.value === valueToForceSet) ||
  //       (multiple
  //         ? null
  //         : {
  //             value: null,
  //             label: "",
  //           });
  //     setValue(multiple ? [newVal] : newVal);
  //   }
  // }, [valueToForceSet]);

  // useEffect(() => {
  //   setValue(valueToForceSet);
  // }, [valueToForceSet]);

  const OutlinedCard = (props) => {
    return (
      <Card
        {...props}
        sx={{
          mb: 2,
          // boxShadow: (theme: Theme) => theme.shadows[20],
          boxShadow: '0px 10px 15px rgba(100, 116, 139, 0.12)',
        }}
        variant={'outlined'}
      />
    );
  };

  return (
    <>
      <Autocomplete
        {...other}
        options={options}
        value={value}
        inputValue={inputValue ?? ''}
        defaultValue={defaultValue ?? multiple ? [] : null}
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
        getOptionLabel={(option) => option?.label ?? ''}
        onChange={handleOnValueChange}
        onInputChange={handleOnInputValueChange}
        loading={RequestStatus.isFetching(fetchStatus)}
        noOptionsText={noValueMessage}
        disabled={disabled}
        PaperComponent={OutlinedCard}
        multiple={multiple}
        disableCloseOnSelect={multiple}
        {...(limitTags && { limitTags })}
        slotProps={{
          popper: {
            sx: {
              zIndex: 2147483640,
            },
          },
        }}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              label={option.label}
              sx={{
                borderRadius: '16px !important',
                '& > svg': {
                  height: '24px',
                  width: '24px',
                },
              }}
            />
          ));
        }}
        renderInput={(props) => {
          return (
            <TextField
              {...props}
              inputRef={inputRef}
              error={error}
              label={label && (requiredSign ? `${label} *` : label)}
              {...(!!placeholder && { placeholder: placeholder })}
              {...(className && { className })}
              sx={sx}
              inputProps={{
                ...props?.inputProps,
                form: {
                  autocomplete: 'off',
                },
              }}
            />
          );
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              padding: '0 16px',
              ...(option?.disabled && { backgroundColor: MUItheme.palette.divider }),
            }}
            key={option.value}
            onClick={(e) => (option?.disabled ? null : props.onClick(e))}
          >
            {multiple && withCheckboxes && (
              <Checkbox disabled={option?.disabled} style={{ marginRight: 8 }} checked={selected} />
            )}
            {option?.icon && (
              <ListItemIcon disabled={option?.disabled} sx={{ minWidth: 'unset', mr: 0.5, ml: -1 }}>
                {option.icon}
              </ListItemIcon>
            )}
            <ListItemText
              disabled={option?.disabled}
              primary={option.label}
              {...(option?.labelTypographyProps && {
                primaryTypographyProps: option.labelTypographyProps,
              })}
              {...(option?.secondaryLabel && {
                secondary: option.secondaryLabel,
              })}
            />
          </li>
        )}
      />
    </>
  );
};

export default InputComboBox;
