import React from 'react';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import { theme } from '../../theme';
import CircularProgress from '@mui/material/CircularProgress';

const NewServiceLayout = (props) => {
  const {
    children,
    onClick,
    buttonLabel = 'Confirm',
    form,
    disabled = false,
    stopDisabled = false,
    loading = false,
    stopLoading = false,
    onStop = null,
    stopButtonLabel = 'Stop Service',
    buttonAlignedToBottom = false,
  } = props;

  return (
    <>
      {/*{buttonAlignedToBottom ? (*/}
      {/*  <Box*/}
      {/*    sx={*/}
      {/*      {*/}
      {/*        // height: `calc(100% - 68px ${onStop ? '* 2 + 8px' : ''})`,*/}
      {/*      }*/}
      {/*    }*/}
      {/*  >*/}
      {/*    {children}*/}
      {/*  </Box>*/}
      {/*) : (*/}
      {/*  children*/}
      {/*)}*/}
      <Box
        sx={
          {
            // my: 1,
            // ...(!buttonAlignedToBottom && { mt: 3 }),
            // mx: -2,
            // display: 'flex',
            // flexDirection: ' column',
            // gap: 1,
            // backgroundColor: 'white',
            // position: 'relative',
          }
        }
      >
        <Box
          sx={{
            pb: onStop
              ? `calc(${theme.sizes.buttonHeightPxVal}px * 2 + 24px)`
              : `calc(${theme.sizes.buttonHeightPxVal}px + 16px)`,
          }}
        >
          {children}
        </Box>
        <Box
          sx={{
            position: 'fixed',
            bottom: `${theme.sizes.bottomBarHeightPxVal}px`,
            zIndex: 10,
            width: 'calc(100% - 64px)',
            backgroundColor: 'white',
            py: 1,
          }}
        >
          {onStop && (
            <Button
              sx={{
                height: `${theme.sizes.buttonHeightPxVal}px !important`,
                // mt: 1,
              }}
              fullWidth
              variant={'outlined'}
              color={'primary'}
              onClick={onStop}
              disabled={stopDisabled || stopLoading}
            >
              {stopButtonLabel} {stopLoading && <CircularProgress size={24} sx={{ ml: 1 }} />}
            </Button>
          )}
          <Button
            sx={{
              height: `${theme.sizes.buttonHeightPxVal}px !important`,
              // my: 1,
              ...(onStop && { mt: 1 }),
            }}
            fullWidth
            variant={'contained'}
            color={'black'}
            {...(form
              ? {
                  form,
                  type: 'submit',
                }
              : {
                  onClick,
                })}
            disabled={disabled || loading}
          >
            {buttonLabel} {loading && <CircularProgress size={24} sx={{ ml: 1 }} />}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default NewServiceLayout;
