import React, { useEffect, useState } from 'react';
import NewServiceLayout from '../NewServiceLayout';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Box, FormHelperText, Tooltip } from '@mui/material';
import NewServiceHeader from '../NewServiceHeader';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { InfoOutlined } from '@mui/icons-material';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { FullImage, GalleryWrapper } from '../../Chat/SingleMessage';
import Input from '../../../components/Forms/ControlledInputs/Input';
import { RequestStatus } from '../../../helpers/RequestStatus';
import CircularProgress from '@mui/material/CircularProgress';
import { MessageImage } from '../PredefinedService/PredefinedServicePreferences';
import { resetAddCustomService } from '../../../reducers/residents.reducer';
import {
  addNewCustomService,
  addNewCustomServiceToHomeVisit,
} from '../../../reducers/residents.actions';
import { getSearchParam } from '../../../helpers/utilities';
import { useHistory } from 'react-router-dom';
import NoteModal from '../NoteModal';
import { getServicesPath } from '../../../routes';
import { setFormErrors } from '../../../helpers/formHelpers';

const timeOfTheDayOptions = [
  {
    value: 'morning',
    label: 'In the morning',
  },
  {
    value: 'afternoon',
    label: 'In the afternoon',
  },
  {
    value: 'evening',
    label: 'In the evening',
  },
];

const NEW_CUSTOM_SERVICE_FORM_ID = 'NEW_CUSTOM_SERVICE_FORM_ID';

const CustomService = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    add: { fetchStatus: addCustomServiceFetchStatus, errors: addCustomServiceErrors },
  } = useSelector((state) => state.residents.customService);

  const {
    control,
    handleSubmit,
    clearErrors,
    setError,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      date: null,
      time_of_the_day: timeOfTheDayOptions[0].value,
      note: {
        content: '',
        attached_images: [],
      },
    },
  });

  const [noteDrawerOpen, setNoteDrawerOpen] = useState(false);

  const watchNoteContent = watch('note.content');
  const watchNoteImages = watch('note.attached_images');

  const homeVisitId = getSearchParam('home_visit', history)?.split('-')?.join('') ?? null ?? null;

  const handleSubmitForm = (values) => {
    if (homeVisitId) {
      dispatch(
        addNewCustomServiceToHomeVisit({
          residentId: +getSearchParam('resident', history),
          id: homeVisitId,
          data: {
            name: values.name,
            ...((values.note.content.length > 0 || values.note?.attached_images?.length > 0) && {
              note: {
                ...(values.note?.content?.length > 0 && { content: values.note.content }),
                ...(values.note?.attached_images?.length > 0 && {
                  attached_images: values.note.attached_images,
                }),
              },
            }),
          },
        })
      );
    } else {
      dispatch(
        addNewCustomService({
          residentId: +getSearchParam('resident', history),
          data: {
            name: values.name,
            date: values.date,
            time_of_the_day: values.time_of_the_day,
            ...((values.note.content.length > 0 || values.note?.attached_images?.length > 0) && {
              note: {
                ...(values.note?.content?.length > 0 && { content: values.note.content }),
                ...(values.note?.attached_images?.length > 0 && {
                  attached_images: values.note.attached_images,
                }),
              },
            }),
          },
        })
      );
    }
  };

  const onSubmit = (values) => {
    handleSubmitForm(values);
  };

  const handleSaveNote = (values) => {
    if (!Object.keys(values).length) return;
    setValue('note', values);
  };

  useEffect(() => {
    setFormErrors(addCustomServiceErrors, setError);
  }, [addCustomServiceErrors]);

  useEffect(() => {
    clearErrors();
    return () => {
      dispatch(resetAddCustomService());
    };
  }, []);

  useEffect(() => {
    if (RequestStatus.isDone(addCustomServiceFetchStatus)) {
      const path = getSearchParam('returnUrl', history);
      if (path) history.push(decodeURIComponent(path));
      else history.push(getServicesPath());
    }
  }, [addCustomServiceFetchStatus]);

  return (
    <NewServiceLayout
      form={NEW_CUSTOM_SERVICE_FORM_ID}
      loading={RequestStatus.isFetching(addCustomServiceFetchStatus)}
      buttonLabel={'Save'}
    >
      <NoteModal open={noteDrawerOpen} setOpen={setNoteDrawerOpen} saveNote={handleSaveNote} />
      <NewServiceHeader title={'Add custom service'} />
      <Box
      // sx={{
      //   position: 'absolute',
      //   height: '-webkit-fill-available',
      //   // top: '156px',
      //   width: 'calc(100% - 64px)',
      //   pb: '64px',
      //   display: 'flex',
      //   flexDirection: 'column',
      // }}
      >
        <Box
          component={'form'}
          id={NEW_CUSTOM_SERVICE_FORM_ID}
          onSubmit={handleSubmit(onSubmit)}
          // sx={{ overflowY: 'scroll' }}
        >
          <Input
            control={control}
            name={'name'}
            type={'text'}
            standaloneLabel={'What is the name of this service? *'}
            validationMessage={errors?.name?.message ?? null}
          />
          {!homeVisitId && (
            <>
              <Input
                control={control}
                name={'date'}
                type={'datepicker'}
                standaloneLabel={'When would you like this service? *'}
                validationMessage={errors?.date?.message ?? null}
              />
              <Input
                control={control}
                name={'time_of_the_day'}
                type={'dropdown'}
                options={timeOfTheDayOptions}
                standaloneLabel={'What time of the day works best?'}
                validationMessage={errors?.time_of_the_day?.message ?? null}
              />
            </>
          )}
          <Divider sx={{ mt: 3, mb: 1 }} />
          <Typography
            variant={'h6'}
            align={'start'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 1,
            }}
          >
            Note
            <Button
              variant={'outlined'}
              color={'primary'}
              endIcon={<AddCircleOutlineIcon />}
              onClick={() => setNoteDrawerOpen(true)}
            >
              Add note
            </Button>
          </Typography>
          {watchNoteContent.length > 0 && (
            <Box sx={{ display: 'flex', gap: 3 }}>
              <Typography variant={'subtitle2'} sx={{ fontWeight: 'bold' }}>
                Content:
              </Typography>
              <Typography variant={'subtitle2'}>{watchNoteContent}</Typography>
            </Box>
          )}
          {watchNoteImages.length > 0 && (
            <Box sx={{ display: 'flex', gap: 3 }}>
              <Typography variant={'subtitle2'} sx={{ fontWeight: 'bold' }}>
                Images added:
              </Typography>
              <Typography variant={'subtitle2'}>{watchNoteImages.length}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </NewServiceLayout>
  );
};

export default CustomService;
