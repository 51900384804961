import React, { useEffect, useState } from 'react';
import NewServiceLayout from '../NewServiceLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  addInitialNote,
  addNewServiceToHomeVisit,
  addResidentServicePreferences,
  getAvailableServiceDetails,
  getResidentPets,
  getResidentServicePreferenceDetails,
  putResidentServicePreferenceDetails,
  stopResidentService,
} from '../../../reducers/residents.actions';
import { getSearchParam } from '../../../helpers/utilities';
import { RequestStatus } from '../../../helpers/RequestStatus';
import { Box, FormHelperText, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import NewServiceHeader from '../NewServiceHeader';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../../components/Forms/ControlledInputs/Input';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import uploadImage from '../../../helpers/uploadImage';
import styled from 'styled-components';
import { FullImage, GalleryWrapper } from '../../Chat/SingleMessage';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { InfoOutlined } from '@mui/icons-material';
import { setFormErrors } from '../../../helpers/formHelpers';
import {
  resetAddService,
  resetPatchService,
  resetServiceDetails,
  resetStopService,
} from '../../../reducers/residents.reducer';
import { getServicesPath } from '../../../routes';
import NoteModal from '../NoteModal';
import { toast } from 'react-toastify';

const MessageImageContainer = styled.div`
  min-height: 40px;
  display: flex;
`;

export const MessageImage = styled.img`
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 4px;
  object-fit: cover;
`;

const NEW_PREDEFINED_SERVICE_FORM_ID = 'NEW_PREDEFINED_SERVICE_FORM_ID';

const PredefinedServicePreferences = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm({});

  const {
    availableServices: {
      details: { item, fetchStatus },
    },
    pets: { items: pets, fetchStatus: petsFetchStatus },
    residentServicePreferences: {
      details: {
        item: residentServicePreferencesDetailsItem,
        fetchStatus: residentServicePreferencesDetailsFetchStatus,
      },
      patch: { fetchStatus: patchServiceFetchStatus, errors: patchServiceErrors },
      add: {
        details: addServiceDetails,
        fetchStatus: addServiceFetchStatus,
        errors: addServiceErrors,
        note: { fetchStatus: addInitialNoteFetchStatus, errors: noteErrors },
      },
    },
    stopService: { fetchStatus: stopServiceFetchStatus, errors: stopServiceErrors },
  } = useSelector((state) => state.residents);

  const homeVisitId = getSearchParam('home_visit', history)?.split('-')?.join('') ?? null;
  const residentId = +getSearchParam('resident', history);
  const id = +getSearchParam('service', history);

  const [images, setImages] = useState({});
  const [galleryOpenFor, setGalleryOpenFor] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [noteDrawerOpen, setNoteDrawerOpen] = useState(false);

  const getFieldType = (type) => {
    switch (type) {
      case 'dynamic':
        return 'combo-box';
      case 'multiple_choice':
        return 'combo-box';
      case 'dropdown':
        return 'combo-box';
      case 'pet':
        return 'pet';
      case 'single_choice':
        return 'single-choice-buttons';
      case 'photo':
        return 'photo';
      case 'number':
        return 'number';
      case 'textarea':
        return 'textarea';
      case 'datepicker':
        return 'datepicker';
      case 'info':
        return 'info';
      case 'label':
        return 'label';
      default:
        return 'text';
    }
  };

  const getValueFromData = (preference) => {
    return getFieldType(preference.option_type) === 'datepicker'
      ? preference.value
        ? new Date(preference.value)
        : null
      : getFieldType(preference.option_type) === 'pet'
      ? preference?.value?.[0]?.id
      : typeof preference?.value === 'string'
      ? preference?.value?.match(/^\d{4}-\d{2}-\d{2}$/)
        ? +preference.value.split('-').join('')
        : preference.value
      : Array.isArray(preference.value)
      ? preference.value.map((val) => ({ ...val, value: val.id, label: val.value_display }))
      : preference?.value?.id ?? null;
  };

  const addImage = async (e, slug) => {
    e.preventDefault();
    const fileInput = document.getElementById(`file-input-${slug}`);

    fileInput.onchange = async function () {
      if (fileInput.files.length === 0) return;
      const file = fileInput.files[0];
      const reader = new FileReader();
      let image = { id: null, file: null };

      reader.readAsDataURL(file);
      reader.onload = function (e) {
        setImages((prev) => ({ ...prev, [slug]: { ...image, file: e.target.result } }));
      };
    };
    fileInput.click();
  };

  const removeImage = (slug) => {
    const fileInput = document.getElementById(`file-input-${slug}`);
    fileInput.value = '';
    setImages((prev) =>
      Object.fromEntries(Object.entries(prev).filter(([key, value]) => key !== slug))
    );
  };

  const getPetProfile = (id) => {
    return pets.find((pet) => pet.id === id) ?? null;
  };

  const handleSubmitForm = (values) => {
    if (homeVisitId) {
      dispatch(
        addNewServiceToHomeVisit({
          residentId: +getSearchParam('resident', history),
          id: homeVisitId,
          data: {
            service: +getSearchParam('service', history),
            details: values,
          },
        })
      );
    } else {
      if (RequestStatus.isError(residentServicePreferencesDetailsFetchStatus)) {
        dispatch(
          addResidentServicePreferences({
            residentId: +getSearchParam('resident', history),
            // service: +getSearchParam('service', history),
            data: {
              service: +getSearchParam('service', history),
              details: values,
            },
          })
        );
      } else {
        dispatch(
          putResidentServicePreferenceDetails({
            residentId: +getSearchParam('resident', history),
            service: +getSearchParam('service', history),
            data: {
              service: +getSearchParam('service', history),
              details: values,
            },
          })
        );
      }
    }
  };

  const onSubmit = async (values) => {
    clearErrors(Object.entries(errors).map(([key, value]) => key));
    if (Object.keys(images).length > 0) {
      setIsUploading(true);
      try {
        Promise.all(
          Object.entries(images).map(async ([key, value]) => {
            if (value?.isUploaded) return { [key]: value.uploadedId };
            else {
              const fileInput = document.getElementById(`file-input-${key}`);
              const uploaded = await uploadImage(fileInput.files[0]);
              return {
                [key]: uploaded.id,
              };
            }
          })
        )
          .then((results) => {
            let imagesIds = {};
            results.map((result) => {
              const [key, value] = Object.entries(result)[0];
              // removeImage(key);
              values[key] = [value];
              imagesIds[key] = value;
            });
            setImages((prev) =>
              Object.fromEntries(
                Object.entries(prev).map(([key, value]) => [
                  key,
                  {
                    ...value,
                    ...(!!imagesIds[key] ? { isUploaded: true, uploadedId: imagesIds[key] } : {}),
                  },
                ])
              )
            );
            setIsUploading(false);
            handleSubmitForm(values);
          })
          .catch((err) => setIsUploading(false));
      } catch (err) {
        setIsUploading(false);
      }
    } else {
      handleSubmitForm(values);
    }
  };

  const redirectAfterSaving = () => {
    const path = getSearchParam('returnUrl', history);
    if (path) history.push(decodeURIComponent(path));
    else history.push(getServicesPath());
  };

  const handleSaveNote = (values) => {
    dispatch(
      addInitialNote({
        residentId: +getSearchParam('resident', history),
        service: +getSearchParam('service', history),
        data: values,
      })
    );
  };

  const handleStopService = () => {
    dispatch(
      stopResidentService({
        residentId,
        service: id,
      })
    );
  };

  useEffect(() => {
    if (residentId && id) {
      dispatch(
        getAvailableServiceDetails({
          residentId,
          id,
        })
      );
      dispatch(
        getResidentServicePreferenceDetails({
          residentId,
          service: id,
        })
      );
    }
  }, [history.location.search]);

  useEffect(() => {
    if (item) {
      item.preference_options.map((preference) => {
        const value = getValueFromData(preference);
        return preference.option_type === 'photo'
          ? preference.value !== null && preference.value?.length > 0
            ? setImages((prev) => ({
                ...prev,
                [preference.slug]: {
                  file: preference.value?.[0]?.original,
                  isUploaded: true,
                  uploadedId: preference.value?.[0]?.id,
                },
              }))
            : setImages({})
          : setValue(
              preference.slug,
              Array.isArray(value)
                ? value.map((v) => (v.hasOwnProperty('value') ? v.value : v))
                : value
            );
      });
      if (item.preference_options.find((option) => option.option_type === 'pet')) {
        dispatch(getResidentPets({ residentId: +getSearchParam('resident', history) }));
      }
    } else {
      setImages({});
    }
  }, [item]);

  useEffect(() => {
    setFormErrors(
      RequestStatus.isError(residentServicePreferencesDetailsFetchStatus)
        ? addServiceErrors
        : patchServiceErrors,
      setError
    );
  }, [addServiceErrors, patchServiceErrors]);

  useEffect(() => {
    if (RequestStatus.isDone(addServiceFetchStatus)) {
      setNoteDrawerOpen(true);
      // redirectAfterSaving();
    }
  }, [addServiceFetchStatus]);

  useEffect(() => {
    if (RequestStatus.isDone(stopServiceFetchStatus)) {
      dispatch(
        getAvailableServiceDetails({
          residentId,
          id,
        })
      );
      dispatch(
        getResidentServicePreferenceDetails({
          residentId,
          service: id,
        })
      );
      toast.success(`Service ${item?.name} stopped`);
      if (
        getSearchParam('returnAfterStop', history) === 'true' &&
        getSearchParam('returnUrl', history)
      )
        history.push(getSearchParam('returnUrl', history));
    } else if (RequestStatus.isError(stopServiceFetchStatus)) {
      toast.error(`There was an error stopping service ${item?.name}. Please try again later`);
    }
  }, [stopServiceFetchStatus]);

  useEffect(() => {
    if (RequestStatus.isDone(patchServiceFetchStatus)) {
      redirectAfterSaving();
    }
  }, [patchServiceFetchStatus]);

  useEffect(() => {
    clearErrors();
    return () => {
      clearErrors();
      dispatch(resetPatchService());
      dispatch(resetAddService());
      dispatch(resetServiceDetails());
      dispatch(resetStopService());
      reset();
    };
  }, []);

  return RequestStatus.isFetching(fetchStatus) ||
    RequestStatus.isFetching(residentServicePreferencesDetailsFetchStatus) ? (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        my: 3,
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    item !== null && (
      <NewServiceLayout
        form={NEW_PREDEFINED_SERVICE_FORM_ID}
        loading={
          isUploading ||
          RequestStatus.isFetching(patchServiceFetchStatus) ||
          RequestStatus.isFetching(addServiceFetchStatus)
        }
        onStop={
          RequestStatus.isError(residentServicePreferencesDetailsFetchStatus)
            ? null
            : handleStopService
        }
        stopLoading={RequestStatus.isFetching(stopServiceFetchStatus)}
        stopDisabled={!residentServicePreferencesDetailsItem?.is_active}
      >
        <NewServiceHeader title={`What are your ${item.name.toLowerCase()} preferences?`} />
        <NoteModal
          open={noteDrawerOpen}
          setOpen={setNoteDrawerOpen}
          redirectAfterSaving={redirectAfterSaving}
          saveNote={handleSaveNote}
          saveNoteFetchStatus={addInitialNoteFetchStatus}
          apiErrors={noteErrors}
        />
        <Box>
          <Box
            component={'form'}
            id={NEW_PREDEFINED_SERVICE_FORM_ID}
            onSubmit={handleSubmit(onSubmit)}
            // sx={{ overflowY: 'scroll' }}
          >
            {item.preference_options.map((preference) => {
              const type = getFieldType(preference.option_type);
              return type === 'info' ? (
                <Typography variant={'subtitle2'} align={'start'}>
                  {preference.label}
                </Typography>
              ) : type === 'label' ? (
                <Typography
                  variant={'subtitle2'}
                  align={'start'}
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {preference.label}
                </Typography>
              ) : type === 'photo' ? (
                <Controller
                  control={control}
                  name={preference.slug}
                  // defaultValue={defaultValue}
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { isTouched },
                  }) => (
                    <>
                      <Box sx={{ display: 'block' }}>
                        {preference.label && (
                          <Typography
                            variant={'subtitle2'}
                            align={'start'}
                            sx={{
                              fontWeight: 'bold',
                              mt: 2,
                              mb: 0.5,
                              alignItems: 'center',
                              display: 'flex',
                            }}
                          >
                            {preference.label} {preference.is_required ? '*' : ''}{' '}
                            {preference?.help_text && (
                              <Tooltip
                                title={
                                  <Box dangrouslySetInnerHTML={{ __html: preference?.help_text }} />
                                }
                                arrow
                                disableInteractive
                                enterTouchDelay={0}
                              >
                                <InfoOutlined
                                  sx={{
                                    height: '16px',
                                    width: '16px',
                                  }}
                                />
                              </Tooltip>
                            )}
                          </Typography>
                        )}
                        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
                          <Button
                            variant={'contained'}
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={(e) => addImage(e, preference.slug)}
                            sx={{
                              height: '40px',
                            }}
                          >
                            Add Photo
                          </Button>
                          {images[preference.slug] && (
                            <MessageImageContainer>
                              <MessageImage
                                src={images[preference.slug].file}
                                onClick={() => setGalleryOpenFor(images[preference.slug].file)}
                              />
                              <IconButton onClick={() => removeImage(preference.slug)}>
                                <ClearIcon />
                              </IconButton>
                            </MessageImageContainer>
                          )}
                          {images[preference.slug] && galleryOpenFor && (
                            <GalleryWrapper onClick={() => setGalleryOpenFor(null)}>
                              <FullImage src={galleryOpenFor} />
                            </GalleryWrapper>
                          )}
                        </Box>
                        {errors[preference.slug]?.message && (
                          <FormHelperText error>{errors[preference.slug]?.message}</FormHelperText>
                        )}
                      </Box>
                      <input
                        id={`file-input-${preference.slug}`}
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                      />
                    </>
                  )}
                />
              ) : type !== 'pet' ? (
                <Input
                  control={control}
                  name={preference.slug}
                  type={type}
                  helpText={preference?.help_text}
                  defaultValue={getValueFromData(preference)}
                  {...(['combo-box', 'dropdown', 'single-choice-buttons', 'pet'].includes(type) && {
                    options: [
                      ...(typeof preference.value === 'string' &&
                      typeof preference.choices.find(
                        (choice) => choice.value === preference.value
                      ) === 'undefined'
                        ? [
                            {
                              value: +preference.value.split('-').join(''),
                              label: preference.value_display,
                            },
                          ]
                        : []),
                      ...preference.choices.map((choice) => ({
                        ...choice,
                        label: choice.value_display,
                        value: choice.id,
                      })),
                    ],
                  })}
                  {...(type === 'textarea' && {
                    multiline: true,
                    rows: 3,
                  })}
                  {...(preference?.option_type === 'multiple_choice' && {
                    multiple: true,
                    withCheckboxes: true,
                  })}
                  standaloneLabel={preference.label}
                  requiredSign={preference.is_required}
                  validationMessage={errors?.[preference.slug]?.message ?? null}
                />
              ) : RequestStatus.isDone(petsFetchStatus) ? (
                <Input
                  control={control}
                  name={preference.slug}
                  type={type}
                  helpText={preference?.help_text}
                  defaultValue={preference?.value ? preference.value.value : null}
                  options={preference.choices.map((choice) => ({
                    ...choice,
                    label: choice.value_display,
                    pet: getPetProfile(choice?.id),
                  }))}
                  standaloneLabel={preference.label}
                  requiredSign={preference.is_required}
                  validationMessage={errors?.[preference.slug]?.message ?? null}
                />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    my: 1,
                  }}
                >
                  <CircularProgress size={24} />
                </Box>
              );
            })}
          </Box>
        </Box>
      </NewServiceLayout>
    )
  );
};

export default PredefinedServicePreferences;
