import React from 'react';
import styled from 'styled-components';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Close from '@mui/icons-material/Close';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import { theme } from '../../theme';
import makeStyles from '@mui/styles/makeStyles';
import { getRoutesForDrawerNavigation } from '../../routes';
import { useHistory } from 'react-router-dom';
import AliceHomeTitleLogo from '../../assets/svg/alice-home-title.svg';

const TopButtonContainer = styled.div``;
const LogoContainer = styled.div`
  margin-top: 120px;
  margin-bottom: 60px;
`;
const MenuListContainer = styled.div``;

const Logo = styled.img``;

const DrawerContent = styled.div`
  @media (min-width: ${theme.responsive.maxContentWidthPxVal}px) {
    margin-left: ${(props) => props.wideScreenPadding}px;
  }
`;

const VersionContainer = styled.div`
  padding-bottom: 16px;
  font-size: 16px;
  @media (min-width: ${theme.responsive.maxContentWidthPxVal}px) {
    margin-left: ${(props) => props.wideScreenPadding}px;
  }
`;

const useStyles = makeStyles((materialTheme) => ({
  root: {
    zIndex: `9990 !important`,
  },
  paper: {
    backgroundColor: theme.colors.blackTheme,
    color: theme.colors.white,
    fontSize: '18px',
    fontWeight: 400,
    width: '70vw',
    maxWidth: 600,
    paddingLeft: theme.paddings.appPaddingPxVal,
    paddingRight: theme.paddings.appPaddingPxVal,
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [`@media (min-width: ${theme.responsive.maxContentWidthPxVal}px)`]: {
      maxWidth: 'unset',
    },
  },
  closeButtonRoot: {
    paddingLeft: 8,
  },
  listItemRoot: {
    paddingLeft: 0,
  },
  title: {
    color: theme.colors.white,
    flexGrow: 1,
    textAlign: 'center',
  },
}));

const MenuDrawer = (props) => {
  const { isMenuDrawerOpen, setIsMenuDrawerOpen, signOut } = props;

  const classes = useStyles();
  const history = useHistory();

  const version = process.env.REACT_APP_VERSION ?? null;

  const logout = { label: 'LOG OUT', action: signOut };
  const menuOptions = [...getRoutesForDrawerNavigation(), logout];

  const handleMenuItemClick = (item) => {
    setIsMenuDrawerOpen(false);
    setTimeout(() => {
      if (item.action) {
        item.action();
      } else if (item.path) history.push(item.path);
    }, 100);
  };

  const getWideScreenPadding = () => {
    const toolbarContainer = document.querySelector(`.toolbar-container`);
    return (
      toolbarContainer &&
      toolbarContainer.offsetLeft > theme.paddings.appPaddingPxVal &&
      toolbarContainer.offsetLeft - theme.paddings.appPaddingPxVal
    );
  };

  return (
    <SwipeableDrawer
      anchor="left"
      open={isMenuDrawerOpen}
      onClose={() => setIsMenuDrawerOpen(false)}
      onOpen={() => setIsMenuDrawerOpen(true)}
      classes={{ root: classes.root, paper: classes.paper }}
    >
      <DrawerContent wideScreenPadding={isMenuDrawerOpen && getWideScreenPadding()}>
        <TopButtonContainer>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setIsMenuDrawerOpen(false)}
            classes={{ root: classes.closeButtonRoot }}
            size="large"
          >
            <Close fontSize="large" />
          </IconButton>
        </TopButtonContainer>

        <LogoContainer>
          <Logo src={AliceHomeTitleLogo} />
        </LogoContainer>

        <MenuListContainer>
          <List>
            {menuOptions.map((item, idx) => (
              <ListItem button key={idx} classes={{ root: classes.listItemRoot }}>
                <ListItemText
                  primary={item.label}
                  onClick={(event) => handleMenuItemClick(item, event)}
                />
              </ListItem>
            ))}
          </List>
        </MenuListContainer>
      </DrawerContent>
      <VersionContainer wideScreenPadding={isMenuDrawerOpen && getWideScreenPadding()}>
        {version ? `v${version}` : ''}
      </VersionContainer>
    </SwipeableDrawer>
  );
};

export default MenuDrawer;
