import React from 'react';
import { theme } from '../theme';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import leftTriangle from 'assets/svg/left-triangle.svg';
import leftTriangleDisabled from 'assets/svg/left-triangle-disabled.svg';
import rightTriangle from 'assets/svg/right-triangle.svg';
import ReplayIcon from '@mui/icons-material/Replay';

export const BlackButton = (props) => {
  const { disabled, flex } = props;

  const useStyles = makeStyles({
    root: {
      backgroundColor: disabled ? theme.colors.medLightGray : theme.colors.blackTheme,
      color: theme.colors.white,
      //height: `${theme.sizes.buttonHeightPxVal}px`,
      //lineHeight: `${theme.sizes.buttonHeightPxVal}px`,
      '&:hover': {
        background: theme.colors.blackTheme,
      },
      fontSize: theme.fontSizes.regular,
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: '0.15em',
      textAlign: 'center',
      borderRadius: '4px',
      width: '100%',
      flex,
    },
  });
  const classes = useStyles();
  const { children, text = '', type, onClick } = props;

  return (
    <Button {...props} className={classes.root} type={type} onClick={onClick}>
      {children || text}
    </Button>
  );
};

export const BlueButton = (props) => {
  const useStyles = makeStyles({
    root: {
      background: theme.colors.blueTint3,
      color: theme.colors.white,
      padding: '6px 0',
      width: '100%',
      fontWeight: 500,
      fontSize: theme.fontSizes.regular,
      letterSpacing: '0.15em',
      borderRadius: 0,
      '&:hover': {
        background: theme.colors.blueTint3,
      },
    },
  });
  const classes = useStyles();

  const { children, text = '', type, onClick } = props;

  return (
    <Button {...props} type={type} onClick={onClick} className={classes.root}>
      {children || text}
    </Button>
  );
};

export const WhiteBorderedButton = (props) => {
  const { isLink, path, children, text = '', type, onClick } = props;

  const useStyles = makeStyles({
    root: {
      border: `1px solid ${props.disabled ? theme.colors.medLightGray : theme.colors.blueTint2}`,
      color: theme.colors.blueTint2,
      borderRadius: '4px',
      background: 'white',
      width: '100%',
      padding: '10px 0px',
      letterSpacing: '0.15em',
      height: '40px',
      textDecoration: 'none',

      '&:hover': {
        background: 'white',
      },
    },
  });
  const classes = useStyles();

  if (isLink) {
    return (
      <Button {...props} type={type} onClick={onClick} className={classes.root}>
        <Link to={path} exact={true} style={{ textDecoration: 'none', color: 'inherit' }}>
          {children || text}
        </Link>
      </Button>
    );
  }

  return (
    <Button {...props} type={type} onClick={onClick} className={classes.root}>
      {children || text}
    </Button>
  );
};

const usePrevNextButtonStyles = makeStyles({
  root: {
    background: theme.colors.white,
    border: `1px solid ${theme.colors.lightGray}`,
    boxSizing: 'border-box',
    borderRadius: 4,
    color: theme.colors.white,
    padding: '6px 0',
    width: '36px',
    height: '36px',
    minWidth: 'unset',
    minHeight: 'unset',
    fontWeight: 500,
    fontSize: theme.fontSizes.regular,
    letterSpacing: '0.15em',
    '&:hover': {
      background: theme.colors.lightGray,
    },
  },
  disabled: {
    color: theme.colors.medLightGray,
  },
});

const ButtonImg = styled.img``;

export const PrevNextButton = (props) => {
  const classes = usePrevNextButtonStyles();
  const { children, text = '', disabled, type = 'prev', onClick } = props;

  return (
    <Button {...props} type={type} onClick={onClick} className={classes.root}>
      <ButtonImg
        src={type === 'prev' ? (disabled ? leftTriangleDisabled : leftTriangle) : rightTriangle}
      />
    </Button>
  );
};

const ReplyIconContainer = styled.div`
  svg {
    fill: ${theme.colors.blueTint2};
    padding-top: 6px;
  }
`;

export const RevertButton = (props) => {
  const { children, text = '', disabled, type = 'prev', onClick } = props;

  const useStyles = makeStyles({
    root: {
      border: `1px solid ${props.disabled ? theme.colors.medLightGray : theme.colors.blueTint2}`,
      color: theme.colors.blueTint2,
      borderRadius: '4px',
      background: 'white',
      width: '100%',
      padding: '10px 0px',
      letterSpacing: '0.15em',
      height: '40px',
      textDecoration: 'none',
      '&:hover': {
        background: 'white',
      },
      marginTop: 22,
      flex: 1,
      marginRight: 8,
    },
  });
  const classes = useStyles();

  return (
    <Button {...props} type={type} onClick={onClick} className={classes.root}>
      <ReplyIconContainer>
        <ReplayIcon />
      </ReplyIconContainer>
    </Button>
  );
};
