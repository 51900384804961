import React, { useEffect, useState } from 'react';
import { DatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import EventIcon from '@mui/icons-material/Event';

const InputDatePicker = (props) => {
  const {
    onChange,
    value,
    label,
    placeholder,
    requiredSign,
    name,
    fullWidth,
    sx,
    ref,
    error,
  } = props;

  const [internalValue, setInternalValue] = useState(
    value
      ? typeof value.getMonth === 'function'
        ? new Date(value.toISOString().slice(0, -1))
        : new Date(new Date(value).toISOString().slice(0, -1))
      : null
  );

  const formatDate = (date) => format(date, 'yyyy-MM-dd');

  useEffect(() => {
    onChange(internalValue ? internalValue : null);
  }, [internalValue]);

  return (
    <MobileDatePicker
      label={label ? `${label || placeholder} ${requiredSign ? '*' : ''}` : null}
      onChange={(e) => {
        setInternalValue(formatDate(e));
      }}
      value={
        value
          ? typeof value.getMonth === 'function'
            ? new Date(value.toISOString().slice(0, -1))
            : new Date(new Date(value).toISOString().slice(0, -1))
          : null
      }
      inputRef={ref}
      slotProps={{
        textField: {
          sx: {
            width: '100%',
            ...sx,
          },
          name: name,
          fullWidth: fullWidth,
          InputProps: {
            endAdornment: <EventIcon />,
          },
          error: error,
        },
      }}
    />
  );
};

export default InputDatePicker;
