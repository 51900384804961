import { createSlice } from '@reduxjs/toolkit';
import {
  getResidents,
  getResidentDetails,
  getResidentNote,
  putResidentNote,
  getResidentPets,
  patchResidentDetails,
  getAvailableServices,
  getAvailableServiceDetails,
  getResidentServicePreferencesList,
  addResidentServicePreferences,
  putResidentServicePreferenceDetails,
  getResidentServicePreferenceDetails,
  addNewCustomService,
  addInitialNote,
  addNewCustomServiceToHomeVisit,
  addNewServiceToHomeVisit,
  stopResidentService,
} from './residents.actions';
import { RequestStatus } from '../helpers/RequestStatus';
import { toast } from 'react-toastify';
import { showErrorMessagesForAction } from '../helpers/ApiRequest';

const initialState = {
  fetchStatus: null,
  list: [],
  details: null,
  note: null,
  errorMessage: null,
  patchFetchStatus: null,
  availableServices: {
    items: [],
    fetchStatus: RequestStatus.status.NULL,
    errors: null,
    details: {
      item: null,
      fetchStatus: RequestStatus.status.NULL,
    },
  },
  residentServicePreferences: {
    items: [],
    fetchStatus: RequestStatus.status.NULL,
    errors: {},
    details: {
      item: null,
      fetchStatus: RequestStatus.status.NULL,
    },
    add: {
      details: null,
      fetchStatus: RequestStatus.status.NULL,
      errors: {},
      note: {
        fetchStatus: RequestStatus.status.NULL,
        errors: {},
      },
    },
    patch: {
      fetchStatus: RequestStatus.status.NULL,
      errors: {},
    },
    delete: {
      fetchStatus: RequestStatus.status.NULL,
      errors: {},
    },
    initialNote: {
      fetchStatus: RequestStatus.status.NULL,
      errors: {},
    },
    stop: {
      fetchStatus: RequestStatus.status.NULL,
      errors: {},
    },
  },
  customService: {
    add: {
      fetchStatus: RequestStatus.status.NULL,
      errors: {},
    },
  },
  pets: {
    items: [],
    fetchStatus: RequestStatus.status.NULL,
    errors: {},
  },
  stopService: {
    fetchStatus: RequestStatus.status.NULL,
    errors: {},
  },
};

const slice = createSlice({
  name: 'residents',
  initialState,
  reducers: {
    updateResidentsFetchStatus(state, action) {
      state.fetchStatus = action.payload;
    },
    clearResidentDetails(state, action) {
      state.details = null;
    },
    resetAvailableServices(state) {
      state.availableServices = initialState.availableServices;
    },
    resetAddService(state) {
      state.residentServicePreferences.add = initialState.residentServicePreferences.add;
    },
    resetPatchService(state) {
      state.residentServicePreferences.patch = initialState.residentServicePreferences.patch;
    },
    resetServiceDetails(state) {
      state.residentServicePreferences.details = initialState.residentServicePreferences.details;
    },
    resetAddCustomService(state) {
      state.customService.add = initialState.customService.add;
    },
    resetAddInitialNote(state) {
      state.residentServicePreferences.add.note = initialState.residentServicePreferences.add.note;
    },
    resetStopService(state) {
      state.stopService = initialState.stopService;
    },
  },
  // don't have actions that can be exported, only respond to other
  extraReducers: {
    [getResidents.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getResidents.fulfilled]: (state, action) => {
      state.fetchStatus = RequestStatus.status.DONE;
      state.list = action.payload.data;
    },
    [getResidents.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.status.ERROR;
      state.errorMessage = action.payload;
    },
    [getResidentDetails.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getResidentDetails.fulfilled]: (state, action) => {
      state.fetchStatus = RequestStatus.status.DONE;
      state.details = action.payload.data;
    },
    [getResidentDetails.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.status.ERROR;
      state.details = null;
      state.errorMessage = action.payload;
    },
    // [getResidentNote.pending]: (state, action) => {
    //   state.fetchStatus = RequestStatus.status.FETCHING;
    // },
    // [getResidentNote.fulfilled]: (state, action) => {
    //   state.fetchStatus = RequestStatus.status.DONE;
    //   state.note = action.payload.data;
    // },
    // [getResidentNote.rejected]: (state, action) => {
    //   state.fetchStatus = RequestStatus.status.ERROR;
    //   state.errorMessage = action.payload;
    // },
    [patchResidentDetails.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
      state.patchFetchStatus = RequestStatus.status.FETCHING;
    },
    [patchResidentDetails.fulfilled]: (state, action) => {
      state.fetchStatus = RequestStatus.status.DONE;
      state.patchFetchStatus = RequestStatus.status.DONE;
      state.details = action.payload.data;
      toast.info(action?.payload?.data?.message || 'Changes saved successfully', {
        autoClose: 3000,
      });
    },
    [patchResidentDetails.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.status.ERROR;
      state.patchFetchStatus = RequestStatus.status.ERROR;
      state.errorMessage = action?.payload?.errors;
      !action?.payload?.errors?.home_visit_occurrences &&
        showErrorMessagesForAction(
          action,
          action?.payload.errors?.next_renewal_date
            ? 'Renewal date: ' + action?.payload.errors?.next_renewal_date
            : null
        );
    },

    // GET available services
    [getAvailableServices.pending]: (state, action) => {
      state.availableServices.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getAvailableServices.fulfilled]: (state, action) => {
      state.availableServices.fetchStatus = RequestStatus.status.DONE;
      state.availableServices.items = action.payload.data;
    },
    [getAvailableServices.rejected]: (state, action) => {
      state.availableServices.fetchStatus = RequestStatus.status.ERROR;
      state.availableServices.errors = action.payload;
    },

    [getAvailableServiceDetails.pending]: (state, action) => {
      state.availableServices.details.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getAvailableServiceDetails.fulfilled]: (state, action) => {
      state.availableServices.details.fetchStatus = RequestStatus.status.DONE;
      state.availableServices.details.item = action.payload.data;
    },
    [getAvailableServiceDetails.rejected]: (state, action) => {
      state.availableServices.details.fetchStatus = RequestStatus.status.ERROR;
      state.availableServices.details.errors = action.payload;
    },

    // Add/Edit service preferences
    [getResidentServicePreferencesList.pending]: (state, action) => {
      state.residentServicePreferences.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getResidentServicePreferencesList.fulfilled]: (state, action) => {
      state.residentServicePreferences.fetchStatus = RequestStatus.status.DONE;
      state.residentServicePreferences.items = action.payload.data;
    },
    [getResidentServicePreferencesList.rejected]: (state, action) => {
      state.residentServicePreferences.fetchStatus = RequestStatus.status.ERROR;
      state.residentServicePreferences.errors = action.payload;
    },

    [getResidentServicePreferenceDetails.pending]: (state, action) => {
      state.residentServicePreferences.details.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getResidentServicePreferenceDetails.fulfilled]: (state, action) => {
      state.residentServicePreferences.details.fetchStatus = RequestStatus.status.DONE;
      state.residentServicePreferences.details.item = action.payload.data;
    },
    [getResidentServicePreferenceDetails.rejected]: (state, action) => {
      state.residentServicePreferences.details.fetchStatus = RequestStatus.status.ERROR;
      state.residentServicePreferences.details.errors = action.payload.errors;
    },

    [addResidentServicePreferences.pending]: (state, action) => {
      state.residentServicePreferences.add.fetchStatus = RequestStatus.status.FETCHING;
    },
    [addResidentServicePreferences.fulfilled]: (state, action) => {
      state.residentServicePreferences.add.fetchStatus = RequestStatus.status.DONE;
      state.residentServicePreferences.add.details = action.payload.data;
    },
    [addResidentServicePreferences.rejected]: (state, action) => {
      state.residentServicePreferences.add.fetchStatus = RequestStatus.status.ERROR;
      state.residentServicePreferences.add.errors = action.payload.errors.details ?? {};
    },

    [addNewServiceToHomeVisit.pending]: (state, action) => {
      state.residentServicePreferences.add.fetchStatus = RequestStatus.status.FETCHING;
    },
    [addNewServiceToHomeVisit.fulfilled]: (state, action) => {
      state.residentServicePreferences.add.fetchStatus = RequestStatus.status.DONE;
      state.residentServicePreferences.add.details = action.payload.data;
    },
    [addNewServiceToHomeVisit.rejected]: (state, action) => {
      state.residentServicePreferences.add.fetchStatus = RequestStatus.status.ERROR;
      state.residentServicePreferences.add.errors = action.payload.errors.details ?? {};
    },

    [putResidentServicePreferenceDetails.pending]: (state, action) => {
      state.residentServicePreferences.patch.fetchStatus = RequestStatus.status.FETCHING;
    },
    [putResidentServicePreferenceDetails.fulfilled]: (state, action) => {
      state.residentServicePreferences.patch.fetchStatus = RequestStatus.status.DONE;
      state.residentServicePreferences.patch.items = action.payload.data;
    },
    [putResidentServicePreferenceDetails.rejected]: (state, action) => {
      state.residentServicePreferences.patch.fetchStatus = RequestStatus.status.ERROR;
      state.residentServicePreferences.patch.errors = action.payload.errors.details ?? {};
    },

    [addNewCustomService.pending]: (state, action) => {
      state.customService.add.fetchStatus = RequestStatus.status.FETCHING;
    },
    [addNewCustomService.fulfilled]: (state, action) => {
      state.customService.add.fetchStatus = RequestStatus.status.DONE;
    },
    [addNewCustomService.rejected]: (state, action) => {
      state.customService.add.fetchStatus = RequestStatus.status.ERROR;
      state.customService.add.errors = action.payload.errors ?? {};
    },

    [addNewCustomServiceToHomeVisit.pending]: (state, action) => {
      state.customService.add.fetchStatus = RequestStatus.status.FETCHING;
    },
    [addNewCustomServiceToHomeVisit.fulfilled]: (state, action) => {
      state.customService.add.fetchStatus = RequestStatus.status.DONE;
    },
    [addNewCustomServiceToHomeVisit.rejected]: (state, action) => {
      state.customService.add.fetchStatus = RequestStatus.status.ERROR;
      state.customService.add.errors = action.payload.errors ?? {};
    },

    [addInitialNote.pending]: (state, action) => {
      state.residentServicePreferences.add.note.fetchStatus = RequestStatus.status.FETCHING;
    },
    [addInitialNote.fulfilled]: (state, action) => {
      state.residentServicePreferences.add.note.fetchStatus = RequestStatus.status.DONE;
    },
    [addInitialNote.rejected]: (state, action) => {
      state.residentServicePreferences.add.note.fetchStatus = RequestStatus.status.ERROR;
      state.residentServicePreferences.add.note.errors = action.payload.errors ?? {};
    },

    [stopResidentService.pending]: (state, action) => {
      state.stopService.fetchStatus = RequestStatus.status.FETCHING;
    },
    [stopResidentService.fulfilled]: (state, action) => {
      state.stopService.fetchStatus = RequestStatus.status.DONE;
    },
    [stopResidentService.rejected]: (state, action) => {
      state.stopService.fetchStatus = RequestStatus.status.ERROR;
      state.stopService.errors = action.payload.errors.details ?? {};
    },
    // Add service to home visit
    // Add/Edit resident's pets
    [getResidentPets.pending]: (state, action) => {
      state.pets.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getResidentPets.fulfilled]: (state, action) => {
      state.pets.fetchStatus = RequestStatus.status.DONE;
      state.pets.items = action.payload.data;
    },
    [getResidentPets.rejected]: (state, action) => {
      state.pets.fetchStatus = RequestStatus.status.ERROR;
      state.pets.errors = action.payload;
    },
  },
});

export const {
  updateBuildingsFetchStatus,
  updateResidentsFetchStatus,
  clearResidentDetails,
  resetAvailableServices,
  resetAddService,
  resetPatchService,
  resetServiceDetails,
  resetAddCustomService,
  resetAddInitialNote,
  resetStopService,
} = slice.actions;

export default slice.reducer;
