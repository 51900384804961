import axios from 'axios';
import _ from 'lodash';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { covertObjToQueryString } from '../helpers/utilities';
import ApiRequest from '../helpers/ApiRequest';

export const getResidents = createAsyncThunk('residents/getResidents', async (data, thunkAPI) => {
  const queryString = _.isObject(data) ? covertObjToQueryString(data) : data;
  const response = await axios.get(`/residents/${queryString ? '?' + queryString : ''}`);
  return response.data;
});

export const getResidentDetails = createAsyncThunk(
  'residents/getResidentDetails',
  async (id, thunkAPI) => {
    const response = await axios.get(`/residents/${id}/`);
    return response.data;
  }
);

export const patchResidentDetails = createAsyncThunk(
  'residents/patchResidentDetails',
  (data, thunkAPI) => {
    const { id, content } = data;
    return ApiRequest.patch(`/residents/${id}/`, { ...content })({ ...content }, thunkAPI);
  }
);

export const getAvailableServices = createAsyncThunk(
  'residents/getAvailableServices',
  async (data, thunkAPI) => {
    const { residentId, homeVisitId } = data;
    return ApiRequest.get(
      `/residents/${residentId}/services/${homeVisitId ? `?home_visit=${homeVisitId}` : ''}`,
      {}
    )({}, thunkAPI);
  }
);

export const getAvailableServiceDetails = createAsyncThunk(
  'residents/getAvailableServiceDetails',
  async (data, thunkAPI) => {
    const { residentId, homeVisitId, id } = data;
    return ApiRequest.get(
      `/residents/${residentId}/services/${id}/${homeVisitId ? `?home_visit=${homeVisitId}` : ''}`,
      {}
    )({}, thunkAPI);
  }
);

export const getResidentServicePreferencesList = createAsyncThunk(
  'residents/getResidentServicePreferencesList',
  async (data, thunkAPI) => {
    const { residentId } = data;
    return ApiRequest.get(`/residents/${residentId}/service-preferences/`, {})({}, thunkAPI);
  }
);

export const addResidentServicePreferences = createAsyncThunk(
  'residents/addResidentServicePreferences',
  async (data, thunkAPI) => {
    const { residentId, data: _data } = data;
    return ApiRequest.post(`/residents/${residentId}/service-preferences/`, {})(_data, thunkAPI);
  }
);

export const addNewServiceToHomeVisit = createAsyncThunk(
  'residents/addNewServiceToHomeVisit',
  async (data, thunkAPI) => {
    const { residentId, id, data: _data } = data;
    return ApiRequest.post(`/residents/${residentId}/home-visit/${id}/add-service/`, {})(
      _data,
      thunkAPI
    );
  }
);

export const getResidentServicePreferenceDetails = createAsyncThunk(
  'residents/getResidentServicePreferenceDetails',
  async (data, thunkAPI) => {
    const { residentId, service } = data;
    return ApiRequest.get(`/residents/${residentId}/service-preferences/${service}/`, {})(
      {},
      thunkAPI
    );
  }
);

export const putResidentServicePreferenceDetails = createAsyncThunk(
  'residents/patchResidentServicePreferenceDetails',
  async (data, thunkAPI) => {
    const { residentId, service, data: _data } = data;
    return ApiRequest.put(`/residents/${residentId}/service-preferences/${service}/`, {})(
      _data,
      thunkAPI
    );
  }
);

export const deleteResidentServicePreferenceDetails = createAsyncThunk(
  'residents/deleteResidentServicePreferenceDetails',
  async (data, thunkAPI) => {
    const { residentId, service } = data;
    return ApiRequest.delete(`/residents/${residentId}/service-preferences/${service}/`, {})(
      {},
      thunkAPI
    );
  }
);

export const addInitialNoteResidentServicePreferenceDetails = createAsyncThunk(
  'residents/addInitialNoteResidentServicePreferenceDetails',
  async (data, thunkAPI) => {
    const { residentId, service, ...other } = data;
    return ApiRequest.post(
      `/residents/${residentId}/service-preferences/${service}/add-initial-note/`,
      {}
    )(other, thunkAPI);
  }
);

export const stopResidentService = createAsyncThunk(
  'residents/stopResidentService',
  async (data, thunkAPI) => {
    const { residentId, service } = data;
    return ApiRequest.post(`/residents/${residentId}/service-preferences/${service}/stop/`, {})(
      {},
      thunkAPI
    );
  }
);

export const getResidentPets = createAsyncThunk(
  'residents/getResidentPets',
  async (data, thunkAPI) => {
    const { residentId } = data;
    return ApiRequest.get(`/residents/${residentId}/pets/`, {})({}, thunkAPI);
  }
);

export const addNewCustomService = createAsyncThunk(
  'residents/addNewCustomService',
  async (data, thunkAPI) => {
    const { residentId, data: _data } = data;
    return ApiRequest.post(`/residents/${residentId}/custom-service/`, {})(_data, thunkAPI);
  }
);

export const addNewCustomServiceToHomeVisit = createAsyncThunk(
  'residents/addNewCustomServiceToHomeVisit',
  async (data, thunkAPI) => {
    const { residentId, id, data: _data } = data;
    return ApiRequest.post(`/residents/${residentId}/home-visit/${id}/add-custom-service/`, {})(
      _data,
      thunkAPI
    );
  }
);

export const addInitialNote = createAsyncThunk(
  'residents/addInitialNote',
  async (data, thunkAPI) => {
    const { residentId, service, data: _data } = data;
    return ApiRequest.post(
      `/residents/${residentId}/service-preferences/${service}/add-initial-note/`,
      {}
    )(_data, thunkAPI);
  }
);
