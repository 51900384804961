import React, { useEffect, useRef, useState } from 'react';
import MobileDrawer from '../../components/MobileDrawer';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Input from '../../components/Forms/ControlledInputs/Input';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { theme } from '../../theme';
import { useForm } from 'react-hook-form';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { FullImage, GalleryWrapper } from '../Chat/SingleMessage';
import styled from 'styled-components';
import { RequestStatus } from '../../helpers/RequestStatus';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/styles';
import uploadImage from '../../helpers/uploadImage';
import { resetAddInitialNote } from '../../reducers/residents.reducer';
import { setFormErrors } from '../../helpers/formHelpers';

const MessageImageContainer = styled.div`
  min-height: 64px;
  display: flex;
  margin: 8px;
  position: relative;
`;

const MessageImage = styled.img`
  width: 64px;
  height: 64px;
  display: block;
  border-radius: 4px;
  object-fit: cover;
`;

const NOTE_FORM_ID = 'NOTE_FORM_ID';

const NoteModal = (props) => {
  const {
    open,
    setOpen,
    redirectAfterSaving = null,
    saveNote = null,
    saveNoteFetchStatus = undefined,
    apiErrors = undefined,
  } = props;

  const dispatch = useDispatch();
  const MUItheme = useTheme();

  const {
    control: noteControl,
    handleSubmit: noteHandleSubmit,
    // getValues,
    // setValue,
    setError,
    formState: { errors },
    clearErrors,
    watch,
  } = useForm({
    defaultValues: {
      content: '',
    },
  });

  const fileInputRef = useRef(null);
  const [images, setImages] = useState([]);
  const [galleryOpenFor, setGalleryOpenFor] = useState(null);
  const [imagesLoading, setImagesLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [isDataSaved, setIsDateSaved] = useState(false);

  const watchNoteContent = watch('content');

  const getDataUrl = (file) => {
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onload = (e) => {
        resolve({
          image: e.target.result,
          name: file.name,
        });
      };
      reader.readAsDataURL(file);
    });
  };

  const fileListToDataUrls = async (fileList) => {
    const promises = [];

    for (let i = 0; i < fileList.length; i++) {
      promises.push(getDataUrl(fileList[i]));
    }

    return await Promise.all(promises);
  };

  const addNoteImages = async (e) => {
    e.preventDefault();
    const fileInput = fileInputRef.current;

    fileInput.onchange = async () => {
      if (fileInput.files.length === 0) return;
      setImagesLoading(true);
      const files = await fileListToDataUrls(fileInputRef.current.files);
      setImages(files);
      setImagesLoading(false);
    };
    fileInput.click();
  };

  const removeNoteImage = (name) => {
    setImages((prev) => prev.filter((image) => image.name !== name));
  };

  const onNoteSubmit = (values) => {
    if (images.length) {
      setIsUploading(true);
      try {
        const fileInput = fileInputRef.current;
        Promise.all(
          images.map(async (image, idx) => {
            if (image?.uploadedId) return image;
            else {
              const uploaded = await uploadImage(fileInput.files[idx]);
              return {
                ...image,
                uploadedId: uploaded.id,
              };
            }
          })
        )
          .then((results) => {
            setImages(results);
            values.attached_images = results.map((result) => result.uploadedId);
            setIsUploading(false);
            saveNote?.(values);
            typeof saveNoteFetchStatus === 'undefined' && setIsDateSaved(true);
          })
          .catch((err) => setIsUploading(false));
      } catch (err) {
        setIsUploading(false);
      }
    } else {
      values.attached_images = [];
      saveNote?.(values);
      typeof saveNoteFetchStatus === 'undefined' && setIsDateSaved(true);
    }
  };

  useEffect(() => {
    if (typeof saveNoteFetchStatus !== 'undefined' && RequestStatus.isDone(saveNoteFetchStatus)) {
      dispatch(resetAddInitialNote());
      setIsDateSaved(true);
    }
  }, [saveNoteFetchStatus]);

  useEffect(() => {
    if (isDataSaved) {
      setIsDateSaved(false);
      setOpen(false);
      redirectAfterSaving?.();
    }
  }, [isDataSaved]);

  useEffect(() => {
    if (typeof apiErrors === 'undefined') return;
    setFormErrors(apiErrors, setError);
  }, [apiErrors]);

  useEffect(() => {
    clearErrors();
  }, []);

  return (
    <MobileDrawer
      open={open}
      onClose={() => {
        setOpen(false);
        redirectAfterSaving?.();
      }}
      height={'75vh'}
      hasHeader
      headerConfig={{
        title: 'Leave a note',
        subTitle: null,
        showDivider: true,
      }}
      loading={imagesLoading || isUploading || RequestStatus.isFetching(null)}
    >
      <>
        <Box
          sx={{
            pb: `calc(${theme.sizes.buttonHeightPxVal}px ${
              redirectAfterSaving ? '* 2 + 8px' : '+ 8px'
            }) !important`,
          }}
        >
          <Box
            sx={{
              overflowY: 'scroll',
            }}
          >
            <Box component={'form'} id={NOTE_FORM_ID} onSubmit={noteHandleSubmit(onNoteSubmit)}>
              <Input
                control={noteControl}
                name={'content'}
                type={'text'}
                multiline
                rows={8}
                standaloneLabel={'Leave a note about this next service: *'}
                {...(typeof apiErrors !== 'undefined' && {
                  validationMessage: errors?.content?.message,
                })}
              />
            </Box>
            <Button
              sx={{
                height: `${theme.sizes.buttonHeightPxVal}px !important`,
                mt: 1,
              }}
              fullWidth
              variant={'outlined'}
              color={'primary'}
              onClick={addNoteImages}
            >
              Add photos
            </Button>
            <Box sx={{ display: 'flex', overflowX: 'scroll', pt: 1 }}>
              {images.map((image) => (
                <MessageImageContainer>
                  <MessageImage src={image.image} onClick={() => setGalleryOpenFor(image.image)} />
                  <IconButton
                    onClick={() => removeNoteImage(image.name)}
                    sx={{
                      position: 'absolute',
                      top: '-12px',
                      right: '-12px',
                      p: 0,
                      border: `solid 1px ${MUItheme.palette.text.secondary}`,
                      backgroundColor: `${theme.colors.medLightGray}A0`,
                      '&:hover': {
                        backgroundColor: `${theme.colors.medLightGray}40`,
                      },
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </MessageImageContainer>
              ))}
              {galleryOpenFor && (
                <GalleryWrapper onClick={() => setGalleryOpenFor(null)}>
                  <FullImage src={galleryOpenFor} />
                </GalleryWrapper>
              )}
            </Box>
          </Box>
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            multiple
            style={{ display: 'none' }}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: '8px',
            pt: 1,
            width: 'calc(100% - 32px)',
            height: `calc(${theme.sizes.buttonHeightPxVal}px ${
              redirectAfterSaving ? '* 2 + 8px' : '+ 8px'
            }) !important`,
            backgroundColor: 'white',
          }}
        >
          {redirectAfterSaving && (
            <Button
              sx={{
                height: `${theme.sizes.buttonHeightPxVal}px !important`,
                mb: 1,
              }}
              fullWidth
              variant={'outlined'}
              color={'primary'}
              onClick={redirectAfterSaving}
            >
              Skip
            </Button>
          )}
          <Button
            sx={{
              height: `${theme.sizes.buttonHeightPxVal}px !important`,
            }}
            fullWidth
            variant={'contained'}
            color={'black'}
            form={NOTE_FORM_ID}
            type={'submit'}
            disabled={typeof saveNoteFetchStatus === 'undefined' && watchNoteContent?.length === 0}
          >
            Save{' '}
            {(isUploading || RequestStatus.isFetching(null)) && (
              <CircularProgress size={24} sx={{ ml: 1 }} />
            )}
          </Button>
        </Box>
      </>
    </MobileDrawer>
  );
};

export default NoteModal;
