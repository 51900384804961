import firebase from "firebase/compat";

export const initializeFirebase = () => {
  console.log('firebase initialized');
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  });

  if (process.env.REACT_APP_FIREBASE_MEASUREMENT_ID) {
    firebase.analytics();
  }
};

// Based on https://www.freecodecamp.org/news/how-to-add-push-notifications-to-a-web-app-with-firebase-528a702e13e1/
export const askForPermissionToReceiveNotifications = async () => {
  console.log('Checking....');
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    let token;
    try {
      token = await messaging.getToken();
      console.log('Push initialized');
    } catch (e) {
      console.error(e);
    }
    return token;
  } catch (error) {
    console.error(error);
  }
};

export const unregisterDevice = async () => {
  const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;
  if (!messaging) return;

  await messaging.requestPermission();
  const token = await messaging.getToken();
  // if (token) dispatch(deleteDevice(token));
};

// export const registeSW = () =>
//   navigator.serviceWorker.register('/my-sw.js').then((registration) => {
//     firebase.messaging().useServiceWorker(registration);
//   });
export const getMessaging = () => firebase.messaging();
