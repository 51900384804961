import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { getChatPath, getRoutesForRoutingComponent } from '../../routes';
import { Redirect, Route, useLocation } from 'react-router-dom';
import AppBar from '../../components/AppBar/AppBar';
import CustomBottomNavigation from './BottomNavigation';
import { theme } from '../../theme';
import MenuDrawer from './MenuDrawer';
import {
  askForPermissionToReceiveNotifications,
  getMessaging,
  initializeFirebase,
} from '../../helpers/firebaseServices';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RouteContent = styled.div`
  padding-left: ${theme.paddings.appPaddingPxVal}px;
  padding-right: ${theme.paddings.appPaddingPxVal}px;
  overflow: ${(props) => (props.isBottomMenu ? 'hidden' : 'auto')};
  position: absolute;
  top: ${theme.sizes.navbarHeightPxVal}px;
  bottom: ${theme.sizes.bottomBarHeightPxVal}px;
  left: 0;
  right: 0;
`;

const BottomNavigationSpace = styled.div`
  height: 60px;
`;

function Layout(props) {
  const { appTitle = '', history, getMenuBadges, menuBadges, signOut, postRegisterDevice } = props;

  const fullModalRef = useRef(null);
  const modalRef = useRef(null);
  const [openFullModal, setOpenFullModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [bottomNavigationValue, _setBottomNavigationValue] = useState(0);
  const [onAppBarButtonClick, setOnAppBarButtonClick] = useState({
    onClick: () => {},
  });
  const [isMenuDrawerOpen, setIsMenuDrawerOpen] = useState(false);
  const [showGlobalLoadingIndicator, setShowGlobalLoadingIndicator] = useState(false);
  const location = useLocation();

  let routingElements = [];
  routingElements = routingElements.length === 0 ? getRoutesForRoutingComponent() : routingElements;

  const setBottomNavigationValue = ({ value = 0, bottomNavigationRoutes = [], noHistoryPush }) => {
    const selectedOption = bottomNavigationRoutes[value];
    if (selectedOption?.path && !noHistoryPush) history.push(selectedOption.path);
    _setBottomNavigationValue(value);
  };

  useEffect(() => {
    //todo: set bottom bar based on url here
    //todo: do both request at once
    getMenuBadges();
    const fetchBadgesInterval = setInterval(() => {
      getMenuBadges();
    }, 15 * 1000);

    initializeFirebase();
    askForPermissionToReceiveNotifications().then((token) => {
      if (token) postRegisterDevice({ registration_id: token });
      // getMessaging().onMessage((payload) => {
      //   console.warn('---FCM', payload);
      // });
    });

    return () => {
      clearInterval(fetchBadgesInterval);
    };
  }, []);

  useEffect(() => {
    if (!location?.pathname.includes(`${getChatPath()}/`)) {
      window.scrollTo(0, 0);
    }
  }, [location?.pathname]);

  return (
    <div>
      {routingElements.map((route, idx) => {
        return route.component ? (
          <Route
            key={idx}
            path={route.path}
            exact={route.exact}
            name={route.name}
            render={(itemProps) => (
              <ContentContainer className="content-container">
                <AppBar
                  elevation={0}
                  title={appTitle}
                  onAppBarButtonClick={onAppBarButtonClick}
                  setIsMenuDrawerOpen={setIsMenuDrawerOpen}
                  showGlobalLoadingIndicator={showGlobalLoadingIndicator}
                  {...route?.appBar}
                />
                <MenuDrawer
                  isMenuDrawerOpen={isMenuDrawerOpen}
                  setIsMenuDrawerOpen={setIsMenuDrawerOpen}
                  signOut={signOut}
                />
                <RouteContent className="route-content" isBottomMenu={itemProps.isBottomMenu}>
                  <route.component
                    {...itemProps}
                    fullModalRef={fullModalRef}
                    openFullModal={openFullModal}
                    setOpenFullModal={setOpenFullModal}
                    modalRef={modalRef}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    setOnAppBarButtonClick={setOnAppBarButtonClick}
                    isMenuDrawerOpen={isMenuDrawerOpen}
                    setIsMenuDrawerOpen={setIsMenuDrawerOpen}
                    setShowGlobalLoadingIndicator={setShowGlobalLoadingIndicator}
                  />
                </RouteContent>
              </ContentContainer>
            )}
          />
        ) : null;
      })}
      <CustomBottomNavigation
        bottomNavigationValue={bottomNavigationValue}
        setBottomNavigationValue={setBottomNavigationValue}
        menuBadges={menuBadges}
      />
      <BottomNavigationSpace />
    </div>
  );
}

export default Layout;
