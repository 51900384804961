import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../helpers/RequestStatus';
import { getServices, postServiceComplete } from './services.actions';
import { toast } from 'react-toastify';

const slice = createSlice({
  name: 'services',
  initialState: {
    fetchStatus: null,
    list: [],
  },
  reducers: {
    updateFetchStatus(state, action) {
      state.fetchStatus = action.payload;
    },
    clearServices(state, action) {
      state.list = [];
    },
  },
  // don't have actions that can be exported, only respond to other
  extraReducers: {
    [getServices.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getServices.fulfilled]: (state, action) => {
      state.fetchStatus = RequestStatus.status.DONE;
      state.list = action.payload.data;
    },
    [getServices.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.status.ERROR;
      state.errorMessage = action.payload;
    },
    [postServiceComplete.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [postServiceComplete.fulfilled]: (state, action) => {
      state.fetchStatus = RequestStatus.status.DONE;
      toast.info(action?.payload?.data?.message || 'Changes saved successfully', {
        autoClose: 3000,
      });
    },
    [postServiceComplete.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.status.ERROR;
      state.errorMessage = action.payload;
    },
  },
});

export const { clearServices, updateFetchStatus } = slice.actions;

export default slice.reducer;
