import { createSlice } from '@reduxjs/toolkit';
import { signIn, signOut, getAuthInfo } from './auth.actions';
import { RequestStatus } from '../helpers/RequestStatus';
import { deleteSessionToken, saveSessionToken } from '../helpers/utilities';
import NetworkService from '../helpers/network.service';
import { showErrorMessagesForAction } from '../helpers/ApiRequest';

const onLogout = (state) => {
  state.user = {};
  state.sessionToken = null;
  state.expiry = null;
  saveSessionToken(null);
  NetworkService.resetDefaultHeaders();
};

const auth = createSlice({
  name: 'auth',
  initialState: {
    sessionToken: null,
    expiry: null,
    loginFetchStatus: null,
    loginErrorMessage: null,
    user: {},
  },
  reducers: {
    unauthUser(state, action) {
      deleteSessionToken();
      NetworkService.resetDefaultHeaders();
      state.sessionToken = null;
      state.expiry = null;
      state.user = {};
      state.loginErrorMessage = action.payload;
    },
    updateSessionToken(state, action) {
      state.sessionToken = action.payload;
    },
  },
  // don't have actions that can be exported, only respond to other
  extraReducers: {
    [signIn.pending]: (state, action) => {
      state.loginFetchStatus = RequestStatus.status.FETCHING;
    },
    [signIn.fulfilled]: (state, action) => {
      state.loginFetchStatus = RequestStatus.status.DONE;
      state.user = action.payload.data.me;
      state.sessionToken = action.payload.data.token;
      state.expiry = action.payload.data.expiry;
      saveSessionToken(action.payload.data.token);
      NetworkService.setupDefaultHeaders(action.payload.data.token);
      NetworkService.syncHeaderTokenWithStorage();
    },
    [signIn.rejected]: (state, action) => {
      state.loginFetchStatus = RequestStatus.status.ERROR;
      state.loginErrorMessage = action.payload;
      showErrorMessagesForAction(action);
    },
    [signOut.pending]: (state, action) => {
      state.loginFetchStatus = RequestStatus.status.FETCHING;
    },
    [signOut.fulfilled]: (state, action) => {
      state.loginFetchStatus = RequestStatus.status.DONE;
      onLogout(state);
    },
    [signOut.rejected]: (state, action) => {
      state.loginFetchStatus = RequestStatus.status.ERROR;
      state.loginErrorMessage = action.payload;
      onLogout(state);
    },
    [getAuthInfo.pending]: (state, action) => {},
    [getAuthInfo.fulfilled]: (state, action) => {
      state.user = action.payload.data;
    },
    [getAuthInfo.rejected]: (state, action) => {},
  },
});

export const { unauthUser, updateSessionToken } = auth.actions;

export default auth.reducer;
