import _ from 'lodash';

export function getSessionToken() {
  return localStorage.getItem('sessionToken');
}

export function saveSessionToken(data) {
  localStorage.setItem('sessionToken', data);
}

export function deleteSessionToken() {
  localStorage.removeItem('sessionToken');
  localStorage.removeItem('user');
}

export function getDescendantProp(obj, path) {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}
export const formatMaskedAmountToNumber = (maskedAmount) => {
  return typeof maskedAmount === 'string'
    ? +_.replace(maskedAmount.replace(new RegExp(',', 'g'), ''), '$', '')
    : maskedAmount;
};

export const formatMaskedPercentToNumber = (maskedAmount) =>
  typeof maskedAmount === 'string'
    ? +maskedAmount.replace(new RegExp('%', 'g'), '').replace(new RegExp(',', 'g'), '')
    : maskedAmount;

export const areDevFeatures = () => {
  const parameter = process.env.REACT_APP_DEV_FEATURES;
  return parameter && parameter === 'true';
};

export const reformatPhoneToSubmit = (phoneNr) => {
  return '+1' + phoneNr.replace(new RegExp('-', 'g'), '').replace(new RegExp('_', 'g'), '');
};

export const reformatPhone = (phoneNr) => {
  if (!phoneNr) return null;
  else if (!phoneNr.includes('+1') && phoneNr.includes('-')) return phoneNr;
  let phone = phoneNr.replace('+1', '');
  phone = phone.slice(0, 3) + '-' + phone.slice(3);
  return phone.slice(0, 7) + '-' + phone.slice(7);
};

export const covertObjToQueryString = (data) => {
  let queryParams = new URLSearchParams();
  for (let [key, value] of Object.entries(data)) {
    if (value && !_.isObject(value)) queryParams.set(key, value);
  }
  return queryParams.toString();
};

export const getSearchParam = (key, history) => {
  const searchParams = new URLSearchParams(history.location.search);
  const val = searchParams.get(key);
  return val === 'null' ? null : val ?? null;
};
