import React, { useEffect, useState } from 'react';
import { Box, FormHelperText, IconButton, TextField, Tooltip } from '@mui/material';
import { Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import InputComboBox from './InputComboBox';
import { RequestStatus } from '../../../helpers/RequestStatus';
import ClearIcon from '@mui/icons-material/Clear';
import { NumericFormat } from 'react-number-format';
import Typography from '@mui/material/Typography';
import InputDropdown from '../InputDropdown';
import MenuItem from '@mui/material/MenuItem';
import InputSingleChoiceButtons from './InputSingleChoiceButtons';
import InputPetSelect from './InputPetSelect';
import { InfoOutlined } from '@mui/icons-material';
import InputDatePicker from './InputDatePicker';

export const getClassName = (className, toRemove = null) => {
  if (!className) return '';
  if (!toRemove) return className;
  else
    return className
      .split(' ')
      .filter((name) => name !== toRemove)
      .join(' ');
};

export const errorClassName = 'Mui-error';

const ValidationMessage = ({ message }) => {
  return <FormHelperText error>{message}</FormHelperText>;
};

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="$"
    />
  );
});

const Input = (props) => {
  const {
    type,
    name,
    control,
    defaultValue = null,
    key = null,
    label = null,
    placeholder = null,
    loading = false,
    inputValue = '',
    onInputChange = () => {},
    options = [],
    fullWidth = true,
    validationMessage = null,
    fetchStatus = RequestStatus.status.NULL,
    noValueMessage = 'No items',
    requiredSign = false,
    multiple = false,
    limitTags = null,
    multiline = false,
    rows = null,
    sx = {},
    withCheckboxes = false,
    size = null,
    richTextUnformattedOnChange = null,
    variant = null,
    className = null,
    standaloneLabel = null,
    showClearButton = false,
    clearErrors = null,
    additionalOnChangeAction = null,
    helpText = null,
  } = props;

  const [internalClassName, setInternalClassName] = useState(null);

  useEffect(() => {
    setInternalClassName(getClassName(className, validationMessage ? null : errorClassName));
  }, [className]);

  return (
    <>
      {!!standaloneLabel && (
        <Typography
          variant={'subtitle2'}
          align={'start'}
          sx={{ fontWeight: 'bold', mt: 2, mb: 0.5, alignItems: 'center', display: 'flex' }}
        >
          {standaloneLabel} {requiredSign ? '*' : ''}{' '}
          {helpText && (
            <Tooltip
              title={<Box dangrouslySetInnerHTML={{ __html: helpText }} />}
              arrow
              disableInteractive
              enterTouchDelay={0}
            >
              <InfoOutlined
                sx={{
                  height: '16px',
                  width: '16px',
                }}
              />
            </Tooltip>
          )}
        </Typography>
      )}
      {type === 'dropdown' ? (
        <Box
          sx={{
            flexDirection: 'column',
            mb: validationMessage ? 0 : 1,
            mt: standaloneLabel ? 0 : 2,
          }}
        >
          <Controller
            control={control}
            name={name}
            // defaultValue={defaultValue}
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { isTouched } }) => (
              <>
                <TextField
                  label={label ? `${label || placeholder} ${requiredSign ? '*' : ''}` : null}
                  type={type}
                  onChange={(e) => {
                    onChange(e);
                    if (clearErrors !== null && Boolean(validationMessage)) {
                      clearErrors(name);
                      if (internalClassName.includes(errorClassName)) {
                        setInternalClassName(getClassName(className, errorClassName));
                      }
                    }
                  }}
                  onBlur={onBlur}
                  value={value}
                  inputRef={ref}
                  fullWidth={fullWidth}
                  multiline={multiline}
                  {...(!!size && { size })}
                  {...(rows && { rows })}
                  {...(!!variant && { variant })}
                  {...(!!className && { className: internalClassName })}
                  {...(!!key && { key })}
                  select
                  InputProps={{
                    sx: { px: 0 },
                  }}
                  sx={{
                    ...sx,
                  }}
                  error={Boolean(validationMessage)}
                >
                  {options?.length ? (
                    options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        <Typography
                          sx={{
                            //   ...(selectRef.current !== null && {
                            //     maxWidth: `${selectRef.current.offsetWidth - 14 - 32}px`,
                            //   }),
                            textOverflow: 'ellipsis',
                            overflowX: 'hidden',
                          }}
                        >
                          {option.label}
                        </Typography>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem key={'no-val'} value={''} disabled>
                      {noValueMessage || ''}
                    </MenuItem>
                  )}
                </TextField>
                {validationMessage && <ValidationMessage message={validationMessage} />}
              </>
            )}
          />
          {/*{validationMessage && <ValidationMessage message={validationMessage} />}*/}
        </Box>
      ) : type === 'datepicker' ? (
        <Controller
          control={control}
          name={name}
          // defaultValue={defaultValue}
          render={({ field: { onChange, onBlur, value, ref }, fieldState: { isTouched } }) => (
            <>
              <InputDatePicker
                onChange={onChange}
                value={value}
                label={label}
                placeholder={placeholder}
                requiredSign={requiredSign}
                name={name}
                fullWidth={fullWidth}
                sx={sx}
                ref={ref}
                error={Boolean(validationMessage)}
              />
              {validationMessage && <ValidationMessage message={validationMessage} />}
            </>
          )}
        />
      ) : type === 'combo-box' ? (
        <Controller
          control={control}
          name={name}
          // defaultValue={defaultValue}
          render={({ field: { onChange, onBlur, value, ref }, fieldState: { isTouched } }) => (
            <Box sx={{ flexDirection: 'column' }}>
              <InputComboBox
                isTouched={isTouched}
                onChange={(e) => {
                  onChange(e.target.value);
                  !!additionalOnChangeAction && additionalOnChangeAction(e.target.value);
                }}
                value={value}
                inputRef={ref}
                options={options}
                multiple={multiple}
                limitTags={limitTags}
                label={label ? `${label || placeholder} ${requiredSign ? '*' : ''}` : null}
                error={Boolean(validationMessage)}
                defaultValue={defaultValue}
                fetchStatus={fetchStatus}
                noValueMessage={noValueMessage}
                requiredSign={requiredSign}
                sx={sx}
                withCheckboxes={withCheckboxes}
                className={internalClassName}
              />
              {validationMessage && <ValidationMessage message={validationMessage} />}
            </Box>
          )}
        />
      ) : type === 'single-choice-buttons' ? (
        <Controller
          control={control}
          name={name}
          // defaultValue={defaultValue}
          render={({ field: { onChange, onBlur, value, ref }, fieldState: { isTouched } }) => (
            <Box sx={{ flexDirection: 'column' }}>
              <InputSingleChoiceButtons options={options} value={value} onChange={onChange} />
              {validationMessage && <ValidationMessage message={validationMessage} />}
            </Box>
          )}
        />
      ) : type === 'pet' ? (
        <Controller
          control={control}
          name={name}
          // defaultValue={defaultValue}
          render={({ field: { onChange, onBlur, value, ref }, fieldState: { isTouched } }) => (
            <Box sx={{ flexDirection: 'column' }}>
              <InputPetSelect value={value} onChange={onChange} options={options} />
              {validationMessage && <ValidationMessage message={validationMessage} />}
            </Box>
          )}
        />
      ) : type === 'date' ? (
        <Controller
          control={control}
          name={name}
          // defaultValue={defaultValue}
          render={({ field: { onChange, onBlur, value, ref }, fieldState: { isTouched } }) => (
            <Box sx={{ flexDirection: 'column' }}>
              <DatePicker
                label={label ? `${label || placeholder} ${requiredSign ? '*' : ''}` : null}
                onChange={onChange}
                value={value}
                inputRef={ref}
                slotProps={{
                  popper: {
                    sx: {
                      zIndex: 2147483640,
                    },
                  },
                  textField: {
                    sx: {
                      width: '100%',
                      ...sx,
                    },
                    name: name,
                    fullWidth: fullWidth,
                  },
                }}
              />
              {validationMessage && <ValidationMessage message={validationMessage} />}
            </Box>
          )}
        />
      ) : type === 'amount' ? (
        <Controller
          control={control}
          name={name}
          // defaultValue={defaultValue}
          render={({ field: { onChange, onBlur, value, ref }, fieldState: { isTouched } }) => (
            <Box sx={{ flexDirection: 'column', ...sx }}>
              <TextField
                label={label ? `${label || placeholder} ${requiredSign ? '*' : ''}` : null}
                type={type}
                onChange={(e) => {
                  onChange(e);
                  if (clearErrors !== null && Boolean(validationMessage)) {
                    clearErrors(name);
                    if (internalClassName.includes(errorClassName)) {
                      setInternalClassName(getClassName(className, errorClassName));
                    }
                  }
                }}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                fullWidth={fullWidth}
                {...(!!className && { className: internalClassName })}
                {...(!!key && { key })}
                {...(!!size && { size })}
                {...(rows && { rows })}
                InputProps={{
                  endAdornment: value?.length > 0 && showClearButton && (
                    <IconButton
                      onClick={() => onChange({ target: { value: '' } })}
                      sx={{ mr: 0.5 }}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                  inputComponent: NumericFormatCustom,
                  sx: { px: 0 },
                }}
              />
              {validationMessage && <ValidationMessage message={validationMessage} />}
            </Box>
          )}
        />
      ) : (
        <Controller
          control={control}
          name={name}
          // defaultValue={defaultValue}
          render={({ field: { onChange, onBlur, value, ref }, fieldState: { isTouched } }) => (
            <Box sx={{ flexDirection: 'column', ...sx }}>
              <TextField
                label={label ? `${label || placeholder} ${requiredSign ? '*' : ''}` : null}
                type={type}
                onChange={(e) => {
                  onChange(e);
                  if (clearErrors !== null && Boolean(validationMessage)) {
                    clearErrors(name);
                    if (internalClassName.includes(errorClassName)) {
                      setInternalClassName(getClassName(className, errorClassName));
                    }
                  }
                }}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                fullWidth={fullWidth}
                multiline={multiline}
                {...(!!size && { size })}
                {...(rows && { rows })}
                {...(!!variant && { variant })}
                {...(!!className && { className: internalClassName })}
                {...(!!key && { key })}
                error={Boolean(validationMessage)}
                InputProps={{
                  endAdornment: value?.length > 0 && showClearButton && (
                    <IconButton
                      onClick={() => onChange({ target: { value: '' } })}
                      sx={{ mr: 0.5 }}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                  sx: { px: multiline ? 1 : 0 },
                }}
              />
              {validationMessage && <ValidationMessage message={validationMessage} />}
            </Box>
          )}
        />
      )}
    </>
  );
};

export default Input;
