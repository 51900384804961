import { createSlice } from '@reduxjs/toolkit';
import { getChats } from './chat.actions';
import { RequestStatus } from '../helpers/RequestStatus';

const slice = createSlice({
  name: 'chats',
  initialState: {
    fetchStatus: null,
    list: [],
    details: null,
  },
  reducers: {
    updateChatsFetchStatus(state, action) {
      state.fetchStatus = action.payload;
    },
  },
  // don't have actions that can be exported, only respond to other
  extraReducers: {
    [getChats.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },

    [getChats.fulfilled]: (state, action) => {
      state.fetchStatus = RequestStatus.status.DONE;
      state.list = action.payload.data;
    },
    [getChats.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.status.ERROR;
      state.errorMessage = action.payload;
    },
  },
});

export const {} = slice.actions;

export default slice.reducer;
