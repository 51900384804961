import './App.css';
import React, { useEffect } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Layout from './containers/Layout';
import { materialTheme } from './theme';
import SWSnackbar from './components/SWSnackbar';
import SyncApp from './containers/SyncApp';
import UnauthLayout from './containers/Layout/UnauthLayout';
import { history } from './helpers/history';
import PrivateRoute from './containers/PrivateRoute';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StyledEngineProvider } from '@mui/styled-engine';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as Sentry from '@sentry/react';

function App() {
  const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
  const SENTRY_SAMPLE_RATE = +process.env.REACT_APP_SENTRY_SAMPLE_RATE;
  const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT;

  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: SENTRY_SAMPLE_RATE,
    environment: SENTRY_ENVIRONMENT,
  });

  return (
    <div className="App">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={materialTheme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SWSnackbar />
            <ToastContainer />
            <HashRouter history={history}>
              <Switch>
                <Route path="/login" component={UnauthLayout} />
                {/*<Route path="/register" component={Register} />*/}
                {/*<Route path="/remind" component={Remind} />*/}
                {/*<Route path="/recovery/:key" component={Recovery} />*/}
                {/*<Route exact path="/404" name="Page 404" component={Page404} />*/}
                <SyncApp>
                  <PrivateRoute path="/" name="App" component={Layout} />
                </SyncApp>
              </Switch>
            </HashRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}

export default App;
