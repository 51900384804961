import PrivateRoute from './PrivateRoute';
import { connect } from 'react-redux';
import { getAuthInfo, syncTokenWithStore } from '../../reducers/auth.actions';

const mapStateToProps = (state, otherProps) => {
  return {
    token: state.auth.sessionToken,
  };
};
export default connect(mapStateToProps, { syncTokenWithStore, getAuthInfo })(PrivateRoute);
