import React, { useEffect, useRef, useState } from 'react';
import { Box, Drawer } from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Clear } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import Button from '@mui/material/Button';
import { theme } from '../theme';
import { BlackButton } from './Buttons';
import LinearProgress from '@mui/material/LinearProgress';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

const MobileDrawer = (props) => {
  const {
    open,
    anchor = 'bottom',
    onClose,
    children,
    loading = false,
    paperSx = {},
    height = '25vh',
    hasHeader = false,
    headerConfig = {
      title: null,
      subTitle: null,
      showDivider: false,
    },
    hasActions = false,
    actions = {
      onCancel: null,
      onAccept: null,
    },
    form = null,
  } = props;

  const MUItheme = useTheme();

  return (
    <SwipeableDrawer
      open={open}
      anchor={anchor}
      onClose={onClose}
      variant={'temporary'}
      sx={{
        zIndex: 9991,
      }}
      PaperProps={{
        sx: {
          ...(height && { height }),
          mt: 2,
          p: 2,
          borderRadius: '16px 16px 0 0',
          maxHeight: '85%',
          ...paperSx,
        },
      }}
    >
      {hasHeader && !headerConfig.showDivider && (
        <Box sx={{ height: '4px', m: -2, mb: 2 }}>{loading && <LinearProgress />}</Box>
      )}
      {hasHeader ? (
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {headerConfig?.title && (
              <Typography variant={'h5'} sx={{ fontWeight: 'bold' }}>
                {headerConfig.title}
              </Typography>
            )}
            <IconButton
              onClick={onClose}
              sx={{
                backgroundColor: MUItheme.palette.divider,
              }}
            >
              <Clear />
            </IconButton>
          </Box>
          {headerConfig?.subTitle && (
            <Typography variant={'subtitle2'} color={'textSecondary'}>
              {headerConfig.subTitle}
            </Typography>
          )}
          {headerConfig?.showDivider && (
            <>
              <Divider sx={{ mt: 1, mx: -2 }} />
              <Box sx={{ height: '4px', mx: -2, mb: 1 }}>{loading && <LinearProgress />}</Box>
            </>
          )}
        </Box>
      ) : (
        <Box sx={{ height: '4px', m: -2, mb: 1 }}>{loading && <LinearProgress />}</Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            overflowY: 'scroll',
          }}
        >
          {children}
        </Box>
      </Box>
      {hasActions && (
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            pt: 1,
            '& > button': {
              height: `${theme.sizes.buttonHeightPxVal}px !important`,
            },
          }}
        >
          {!!actions.onCancel && (
            <Button fullWidth variant={'outlined'} onClick={actions.onCancel}>
              Cancel
            </Button>
          )}
          {(!!actions.onAccept || !!form) && (
            <Button
              variant={'contained'}
              fullWidth
              color={'black'}
              {...(!form ? { onClick: actions.onAccept } : { form, type: 'submit' })}
            >
              Confirm
            </Button>
          )}
        </Box>
      )}
    </SwipeableDrawer>
  );
};

export default MobileDrawer;
